import NeutralWordmarkSrc from '@/assets/wordmark.svg';
import PrimaryWordmarkSrc from '@/assets/wordmark-primary.svg';

import { Wordmark } from './RMWordmark.styles';

export interface RMWordmarkProps {
  color?: 'primary' | 'neutral';
  destination?: 'app' | 'marketing';
  className?: string;
}

const ColorSrcMap = {
  primary: PrimaryWordmarkSrc,
  neutral: NeutralWordmarkSrc,
} as const;

export function RMWordmark({ destination = 'app', color = 'primary', className }: RMWordmarkProps) {
  const goToMainPage = () => {
    window.open(destination === 'app' ? '/' : 'https://remento.co', '_blank');
  };

  return (
    <Wordmark
      src={ColorSrcMap[color]}
      onClick={goToMainPage}
      className={className}
      alt="Remento Wordmark"
      role="link"
      aria-label="Remento Home"
    />
  );
}

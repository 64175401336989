import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { QuestionnaireAnalyticsService } from './questionnaire-analytics.types.js';

export class DefaultQuestionnaireAnalyticsService implements QuestionnaireAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onQuestionnaireArrived(): void {
    this.browserAnalytics.track(Events.QuestionnaireArrive);
  }

  onQuestionnaireQuestionArrived(question: string): void {
    this.browserAnalytics.track(Events.QuestionnaireQuestionArrive, { question });
  }

  onQuestionnaireQuestionSelected(question: string, option: string): void {
    this.browserAnalytics.track(Events.QuestionnaireQuestionSelect, { question, option });
  }

  onQuestionnaireQuestionDeselected(question: string, option: string): void {
    this.browserAnalytics.track(Events.QuestionnaireQuestionDeselect, { question, option });
  }

  onQuestionnaireResultArrived(question: string): void {
    this.browserAnalytics.track(Events.QuestionnaireResultArrive, { question });
  }

  onQuestionnaireSkipped(): void {
    this.browserAnalytics.track(Events.QuestionnaireSkip);
  }
}

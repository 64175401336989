import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useBooleanQueryParam } from '@/hooks/useQueryParam';

const QUERY_PARAM_NAME = 'referral-open';

export function useOpenReferralDialogUrl(): string {
  const location = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(QUERY_PARAM_NAME, 'true');
    return location.pathname + '?' + searchParams.toString();
  }, [location.pathname, location.search]);
}

export function useOpenReferralDialog(): () => void {
  const [_, setOpenReferralDialog] = useBooleanQueryParam(QUERY_PARAM_NAME);
  return useCallback(() => {
    setOpenReferralDialog(true);
  }, [setOpenReferralDialog]);
}

export function useCloseReferralDialog(): () => void {
  const [_, setOpenReferralDialog] = useBooleanQueryParam(QUERY_PARAM_NAME);
  return useCallback(() => {
    setOpenReferralDialog(null);
  }, [setOpenReferralDialog]);
}

export function useIsReferralDialogOpen(): boolean {
  const [isReferralDialogOpen] = useBooleanQueryParam(QUERY_PARAM_NAME, false);
  return isReferralDialogOpen;
}

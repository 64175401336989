import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { createServices, Services } from '@/Services';

import { RouteType } from '../types/routing.types.js';
import { validateRoute } from '../validators/validator.js';

export interface ValidatingRouteResult {
  status: 'validating';
}

export interface RevalidatingRouteResult {
  status: 'revalidating';
  services: Services;
}

export interface ValidatedRouteResult {
  status: 'valid' | 'invalid';
  services: Services;
}

export type RouteValidationResult = ValidatingRouteResult | RevalidatingRouteResult | ValidatedRouteResult;

// Create the services instance outside of the React lifecycle.
// This way, we can guarantee that we'll only have one instance of the services,
// and we won't need to create a new instance when navigating between pages.
let servicesInstance: Promise<Services> | null = null;

export function useValidateRoute(routeType: RouteType): RouteValidationResult {
  const [status, setStatus] = useState<RouteValidationResult['status']>('validating');
  const [error, setError] = useState<Error | null>(null);

  const [services, setServices] = useState<Services | null>(null);

  const navigate = useNavigate();
  const { pathname, state: locationState } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const redirect = useCallback(
    (nextPathname: string, nextParams?: URLSearchParams): boolean => {
      if (pathname === nextPathname) {
        return false;
      }
      navigate(`${nextPathname}${nextParams ? '?' : ''}${nextParams ? nextParams.toString() : ''}`, { replace: true });
      return true;
    },
    [navigate, pathname],
  );

  // Load the services
  const servicesLoaded = useRef(false);
  useEffect(() => {
    if (servicesLoaded.current) {
      return;
    }
    servicesLoaded.current = true;

    // Only load the services when the hook mounts.
    // This will ensure that all interceptors have been initialized.
    if (servicesInstance === null) {
      servicesInstance = createServices();
    }

    servicesInstance
      .then((services) => {
        setServices(services);
      })
      .catch((error) => {
        servicesInstance = null;
        setError(error);
      });
  }, []);

  // Validate current route
  const lastValidatedPathname = useRef<string | null>(null);
  useLayoutEffect(() => {
    if (services === null || lastValidatedPathname.current === pathname) {
      return;
    }
    // This is only used to avoid re-triggering the useEffect twice in dev mode.
    // We should still should be able to run this effect more than once per page.
    if (lastValidatedPathname.current != null) {
      // If this is not the first time we are running the validation, set
      // the status to revalidating.
      setStatus('revalidating');
    }
    lastValidatedPathname.current = pathname;

    // If we are rendering the route in the error state, we don't need to validate anything.
    if (routeType === 'error') {
      setStatus('valid');
      return;
    }

    validateRoute({
      pathname: pathname,
      params,
      searchParams,
      routeType,
      services,
    })
      .then((result) => {
        let valid = result.valid;
        if (result.valid === false) {
          valid = !redirect(result.redirect.pathname, result.redirect.searchParams);
        } else {
          setSearchParams(result.searchParams, { replace: true, state: locationState });
        }
        setStatus(valid ? 'valid' : 'invalid');
      })
      .catch((error) => {
        setError(error);
      });
  }, [pathname, navigate, params, redirect, routeType, services, searchParams, setSearchParams, locationState]);

  // Error boundaries don't catch errors thrown in callbacks, so we need to rethrow the error during the render
  if (error !== null) {
    throw error;
  }

  if (status !== 'validating' && services) {
    return {
      status,
      services,
    };
  }

  return {
    status: 'validating',
  };
}

import { datadogLogs } from '@datadog/browser-logs';
import { LoggerFactory, LogLevel, setLoggerFactory } from '@remento/logger';
import { BrowserConsoleLogTransport } from '@remento/logger/transport/console.browser';
import { WebsocketTransport } from '@remento/logger/transport/websocket';
import { BrowserDatadogLogTransport } from '@remento/logger-datadog/datadog.browser';

import { getQueryParam } from './hooks/useQueryParam.js';
import { IS_DEV } from './utils/isDev.js';

const remoteLoggerUrl = getQueryParam('remote-logger');
const consoleLogger = getQueryParam('console-logger') === 'true' || IS_DEV;

setLoggerFactory(
  new LoggerFactory({
    transports: [
      consoleLogger ? new BrowserConsoleLogTransport() : null,
      import.meta.env.PROD ? new BrowserDatadogLogTransport(datadogLogs.logger) : null,
      remoteLoggerUrl != null ? new WebsocketTransport(remoteLoggerUrl) : null,
    ],
    defaultLevel: LogLevel.always,
    metadata: {
      application: 'book-webapp',
      package: '@remento/book-webapp',
    },
  }),
);

import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { Project, Prompt, PromptStatus, Question } from '@remento/types/project';
import { User } from '@remento/types/user';
import { notNull } from '@remento/utils/array/notNull';
import { QueryClient } from '@tanstack/react-query';

import { EntityCacheManagerService } from '../cache/entity-cache-manager.types.js';

import { CreatePromptPayload, ProjectCacheService, ProjectService, RequestAccessPayload } from './project.types.js';

export class DefaultProjectCacheService implements ProjectCacheService {
  constructor(
    private remoteService: ProjectService,
    private entityCacheManagerService: EntityCacheManagerService,
    private queryClient: QueryClient,
  ) {}

  async requestAccess(projectId: string, payload: RequestAccessPayload): Promise<User> {
    const response = await this.remoteService.requestAccess(projectId, payload);
    await this.entityCacheManagerService.cacheResponse(response);

    let user: User | undefined;
    if ('entities' in response) {
      user = response.entities.user?.[0];
    } else {
      user = response.user?.[0];
    }

    if (user == null) {
      throw new NotFoundError('request-access-user-not-found');
    }

    return user;
  }

  getProject(projectId: string): Promise<Project | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.PROJECT, projectId, () =>
        this.remoteService.getProject(projectId),
      ),
    );
  }

  getProjects(): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.PROJECT, {}, () =>
        this.remoteService.getProjects(),
      ),
    );
  }

  getProjectPrompts(projectId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.PROMPT, { projectId }, () =>
        this.remoteService.getProjectPrompts(projectId),
      ),
    );
  }

  getProjectsFromRecipientPersonId(personId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.PROJECT, { recipientPersonId: personId }, () =>
        this.remoteService.getProjectsFromRecipientPersonId(personId),
      ),
    );
  }

  async getProjectFromStoryId(storyId: string): Promise<Project | null> {
    const queryResult = await this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.PROJECT, { storyId }, () =>
        this.remoteService.getProjectFromStoryId(storyId),
      ),
    );

    return this.getProject(queryResult[0]);
  }

  async loadProjectPromptsCatchupData(projectId: string): Promise<void> {
    const response = await this.remoteService.getProjectPromptsCatchupData(projectId);
    await this.entityCacheManagerService.cacheResponse(response);
  }

  async getUnrecordedProjectPrompts(projectId: string, status?: PromptStatus): Promise<string[]> {
    const promptIds = await this.getProjectPrompts(projectId);
    const results = await Promise.all(
      promptIds.map(async (promptId) => {
        const prompt = await this.getPrompt(promptId);
        if (prompt === null || prompt.status === PromptStatus.DONE) {
          return null;
        }

        return prompt;
      }),
    );

    let prompts = results.filter(notNull);
    if (status) {
      prompts = prompts.filter((prompt) => prompt.status === status);
    }

    return prompts.map((prompt) => prompt.id);
  }

  getPrompt(promptId: string): Promise<Prompt | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.PROMPT, promptId, () =>
        this.remoteService.getPrompt(promptId),
      ),
    );
  }

  getPromptQuestions(promptId: string): Promise<string[]> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildCollectionQuery(EntityType.QUESTION, { promptId }, () =>
        this.remoteService.getPromptQuestions(promptId),
      ),
    );
  }

  getQuestion(questionId: string): Promise<Question | null> {
    return this.queryClient.fetchQuery(
      this.entityCacheManagerService.buildEntityQuery(EntityType.QUESTION, questionId, () =>
        this.remoteService.getQuestion(questionId),
      ),
    );
  }

  async createPrompts(projectId: string, payload: CreatePromptPayload[]): Promise<Prompt[]> {
    const response = await this.remoteService.createPrompts(projectId, payload);
    await this.entityCacheManagerService.cacheResponse(response);

    // TODO - We can remove this after refactoring all endpoints to the new interface
    const entities = 'entities' in response ? response.entities : response;
    return entities[EntityType.PROMPT] ?? [];
  }

  async deletePrompt(promptId: string): Promise<void> {
    const response = await this.remoteService.deletePrompt(promptId);
    await this.entityCacheManagerService.cacheResponse(response);
  }

  async invalidateProjectsCache(): Promise<void> {
    await this.entityCacheManagerService.invalidateCollection(EntityType.PROJECT, {});
  }
}

import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { ReferralAnalyticsService } from './referral-analytics.types.js';

export class DefaultReferralAnalyticsService implements ReferralAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onReferralShared(shareType: 'share-sheet' | 'email' | 'clipboard'): void {
    this.browserAnalytics.track(Events.ReferralShare, { shareType });
  }
}

import { useCallback, useMemo } from 'react';

import { useSearchParams } from './useSearchParams.js';

export type UseQueryParamReturn<T> = [T, (value: T | null) => void];

export function useBooleanQueryParam(name: string): UseQueryParamReturn<boolean | null>;
export function useBooleanQueryParam(name: string, defaultValue: boolean): UseQueryParamReturn<boolean>;
export function useBooleanQueryParam(
  name: string,
  defaultValue?: boolean,
): UseQueryParamReturn<boolean | null> | UseQueryParamReturn<boolean> {
  const { searchParams, setSearchParam } = useSearchParams();

  const value = searchParams.get(name);
  const setValue = useCallback(
    (newValue: boolean | null) => {
      setSearchParam(name, newValue !== null ? String(newValue) : null);
    },
    [name, setSearchParam],
  );

  const parsedValue = value != null ? value == 'true' : null;
  return [parsedValue ?? defaultValue ?? null, setValue];
}

export function useStringQueryParam(name: string): UseQueryParamReturn<string | null>;
export function useStringQueryParam(name: string, defaultValue: string): UseQueryParamReturn<string>;
export function useStringQueryParam(
  name: string,
  defaultValue?: string,
): UseQueryParamReturn<string | null> | UseQueryParamReturn<string> {
  const { searchParams, setSearchParam } = useSearchParams();

  const value = searchParams.get(name);
  const setValue = useCallback(
    (newValue: string | null) => {
      setSearchParam(name, newValue !== null ? newValue : null);
    },
    [name, setSearchParam],
  );

  return [value ?? defaultValue ?? null, setValue];
}

export function useEnumQueryParam<T extends string>(
  name: string,
  defaultValue: T,
  values: T[],
): UseQueryParamReturn<T> {
  const { searchParams, setSearchParam } = useSearchParams();

  const rawValue = searchParams.get(name);
  const parsedValue = useMemo(() => {
    if (values.includes(rawValue as T)) {
      return rawValue as T;
    }
    return null;
  }, [rawValue, values]);
  const setValue = useCallback(
    (newValue: T | null) => {
      setSearchParam(name, newValue !== null ? newValue : null);
    },
    [name, setSearchParam],
  );

  return [parsedValue ?? defaultValue ?? null, setValue];
}

export function getQueryParam(name: string): string | null {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export function getQueryParamsObject(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const obj: Record<string, string> = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
}

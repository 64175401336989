import { SubscriptionType } from '@remento/types/user';

import { api } from '../api.js';
import { AuthorizationService } from '../authorization/index.js';

import {
  Checkout,
  CheckoutRequest,
  CheckoutService,
  CheckoutSession,
  CheckoutTotalRequest,
  CheckoutTotalResponse,
  TypeformCheckoutRequest,
} from './checkout.types.js';

export class DefaultCheckoutService implements CheckoutService {
  constructor(private authorizationService: AuthorizationService) {}

  async checkout(request: CheckoutRequest): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();

    const { data } = await api.post<Checkout>('/checkout', request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async bundleCheckout(request: TypeformCheckoutRequest): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>('/checkout/bundle', request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async giftCheckout(request: TypeformCheckoutRequest): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>('/checkout/gift', request, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async reactivate(type: SubscriptionType): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>(
      '/checkout/reactivation',
      { type },
      {
        params: credentialsForRequest.params,
        headers: credentialsForRequest.headers,
      },
    );
    return data;
  }

  async earlyRenew(): Promise<Checkout> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.post<Checkout>('/checkout/early-renewal', null, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getCheckoutTotal(request: CheckoutTotalRequest): Promise<CheckoutTotalResponse> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const params = new URLSearchParams(credentialsForRequest.params);
    params.set('projectType', request.projectType);
    params.set('books', String(request.books));
    params.set('ebook', String(request.ebook));
    params.set('legacyboxQuantity', String(request.legacyboxQuantity));
    if (request.coupon != null) {
      params.set('coupon', request.coupon);
    }
    const { data } = await api.get<CheckoutTotalResponse>('/checkout/total', {
      params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }

  async getCheckoutSession(id: string): Promise<CheckoutSession> {
    const credentialsForRequest = await this.authorizationService.getCredentialsForRequest();
    const { data } = await api.get<CheckoutSession>('/checkout-sessions/' + id, {
      params: credentialsForRequest.params,
      headers: credentialsForRequest.headers,
    });
    return data;
  }
}

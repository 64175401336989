import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { Fonts } from '@/styles/base/fonts';

export const InputContainer = styled.div<{ disabled?: boolean; readOnly?: boolean }>`
  display: flex;
  flex-flow: column;
  gap: ${(props) => (props.readOnly ? 'var(--spacing-sm)' : 'var(--spacing-xs)')};
  width: 100%;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  position: relative;
`;

export const InputLabel = styled.label`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  font-weight: 700;
  line-height: 140%;
  color: var(--on-surface-secondary);
  cursor: pointer;
  width: fit-content;
  margin-left: var(--spacing-2xs);
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  error: boolean;
  icon: boolean;
  fontFamily?: keyof typeof Fonts;
}

function getBorderColor({
  error,
  focused = false,
  hovered = false,
}: {
  error: boolean;
  focused?: boolean;
  hovered?: boolean;
}) {
  if (error) {
    return '1px solid var(--danger)';
  }

  if (focused) {
    return '1px solid var(--border-primary)';
  }

  if (hovered) {
    return '1px solid var(--border-stronger)';
  }

  return '1px solid var(--on-surface-tertiary)';
}

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const Input = styled.input<InputProps>`
  // The extra 1px padding is to compensate for the border when
  // the input gets focused (border width 1px -> 2px)
  padding: 1px calc(var(--spacing-md) + 1px);
  padding-left: ${(props) => (props.icon ? 'calc(2.5rem + 1px)' : 'calc(var(--spacing-md) + 1px)')};
  background-color: transparent;
  border: ${({ error }) => getBorderColor({ error })};
  border-radius: var(--radius-regular);
  font-size: 1rem;
  font-family: ${(props) => `var(--font-${props.fontFamily})`};
  color: var(--on-surface-secondary);
  outline: none;
  height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;

  ::placeholder {
    color: #696e6b;
  }

  :hover {
    border: ${({ error }) => getBorderColor({ error, hovered: true })};
  }

  :focus {
    padding: 0 var(--spacing-md);
    padding-left: ${(props) => (props.icon ? '2.5rem' : 'var(--spacing-md)')};
    border: ${({ error }) => getBorderColor({ error, focused: true })};
    border-width: 2px;
  }

  &[type='date'],
  &[type='datetime-local'] {
    body[data-mobile='false'] & {
      display: block;
    }

    &::-webkit-date-and-time-value {
      text-align: left;
    }

    body[data-mobile='true'] & {
      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }
`;

export const TextArea = styled.textarea<InputProps>`
  // The extra 1px padding is to compensate for the border when
  // the input gets focused (border width 1px -> 2px)
  padding: calc(1rem + 1px);
  background-color: transparent;
  border: ${({ error }) => getBorderColor({ error })};
  border-radius: var(--radius-regular);
  font-size: 1rem;
  font-family: ${(props) => `var(--font-${props.fontFamily})`};
  color: var(--on-surface-secondary);
  outline: none;
  width: 100%;
  overflow: hidden;
  min-height: 8.75rem !important;

  ::placeholder {
    color: #696e6b;
  }

  :hover {
    border: ${({ error }) => getBorderColor({ error, hovered: true })};
  }

  :focus {
    padding: 1rem;
    border: ${({ error }) => getBorderColor({ error, focused: true })};
    border-width: 2px;
  }

  :read-only {
    padding: 0;
    padding-left: var(--spacing-2xs);
    border: none;
    color: var(--on-surface-primary);
    resize: none;
  }
`;

export const InputError = styled.span`
  font-size: 0.875rem;
  font-family: var(--font-sans);
  color: var(--danger);
`;

export const Icon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 1rem;
  top: 1rem;
  color: var(--on-surface-secondary);
`;

export const DateIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: var(--spacing-md);
  transform: translateY(-50%);
  pointer-events: none;
`;

import { PropsWithChildren } from 'react';

import { Footer } from './RecordingLayoutFooter.styles';

export type RecordingLayoutFooterProps = PropsWithChildren<{
  hideSeparator?: boolean;
}>;

export function RecordingLayoutFooter({ hideSeparator = false, children }: RecordingLayoutFooterProps) {
  return <Footer data-hide-separator={hideSeparator}>{children}</Footer>;
}

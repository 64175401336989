import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import {
  RecordingSettingsNotificationChangedPayload,
  StorytellingAnalyticsService,
  StorytellingRecordingType,
  StorytellingVariant,
} from './storytelling-analytics.types.js';

export class DefaultStorytellingAnalyticsService implements StorytellingAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onRecordingIntroArrived(isFirstTimeRecording: boolean, referrerRoute: string | null | undefined): void {
    const payload = {
      isFirstTimeRecording,
      referrerRoute,
    };

    this.browserAnalytics.track(Events.StorytellingArrive, payload);
    this.browserAnalytics.track(Events.RecordingIntroArrive, payload);
  }

  onRecordingIntroVideoWatchedArrived(referrerRoute: string | null | undefined) {
    this.browserAnalytics.track(Events.RecordingIntroVideoWatchedArrive, {
      referrerRoute,
    });
  }

  onStorytellingResponseStarted(variant: StorytellingVariant, referrerRoute: string | null | undefined): void {
    this.browserAnalytics.track(Events.StorytellingResponseStart, {
      variant,
      referrerRoute,
    });
  }

  onStorytellingLearnMorePressed(variant: StorytellingVariant, referrerRoute: string | null | undefined): void {
    this.browserAnalytics.track(Events.StorytellingLearnMore, {
      variant,
      referrerRoute,
    });
  }

  onRecordingPromptReviewArrived(): void {
    this.browserAnalytics.track(Events.RecordingPromptReviewArrived, {});
  }

  onRecordingTypeSelectionArrived(): void {
    this.browserAnalytics.track(Events.RecordingTypeSelectionArrive, {});
  }

  onRecordingTypeSetupArrived(recordingType: StorytellingRecordingType) {
    this.browserAnalytics.track(Events.RecordingTypeSetupArrive, { recordingType });
  }

  onStorytellingInputSelected(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingInputsSelect, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputPermissionDeclined(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingInputsDecline, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputBrowseOpened(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingInputsBrowse, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingInputChanged(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingInputsChange, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onRecordingInputsReviewArrived(): void {
    this.browserAnalytics.track(Events.RecordingInputsReviewArrive, {});
  }

  onRecordingReadyArrived(): void {
    this.browserAnalytics.track(Events.RecordingReadyArrive, {});
  }

  onStorytellingRecordingStarted(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingStart, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingNextPrompt(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingNextPrompt, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRestarted(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingRestart, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingEnded(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingEnd, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onRecordingReviewArrived(): void {
    this.browserAnalytics.track(Events.RecordingReviewArrive, {});
  }

  onStorytellingRecordingFinished(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingFinish, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onStorytellingRecordingSucceeded(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    duration: number,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingSuccess, {
      variant,
      recordingType,
      duration,
      referrerRoute,
    });
  }

  onStorytellingRecordingInterruptedError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingInterruptError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingNoDataError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingNoDataError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingRecordingQueueDisconnectedError(
    variant: StorytellingVariant,
    recordingType: StorytellingRecordingType,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.StorytellingRecordingQueueDisconnectError, {
      variant,
      recordingType,
      referrerRoute,
    });
  }

  onStorytellingActionPerformed(cta: 'view-stories', referrerRoute: string | null | undefined): void {
    this.browserAnalytics.track(Events.StorytellingAction, { cta, referrerRoute });
  }

  onRecordingSettingsNotificationChanged(
    payload: RecordingSettingsNotificationChangedPayload,
    referrerRoute: string | null | undefined,
  ): void {
    this.browserAnalytics.track(Events.RecordingSettingsNotificationChanged, { ...payload, referrerRoute });
  }
}

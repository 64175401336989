import { createLogger } from '@remento/logger';

interface Logger {
  debug(message: string, messageContext?: object, error?: Error): void;
  info(message: string, messageContext?: object, error?: Error): void;
  warn(message: string, messageContext?: object, error?: Error): void;
  error(message: string, messageContext?: object, error?: Error): void;
}

class LegacyLogger implements Logger {
  private logger = createLogger('legacy');

  debug(message: string, messageContext?: object, error?: Error): void {
    this.logger.debug(message, () => ({ ...messageContext, error }));
  }

  info(message: string, messageContext?: object, error?: Error): void {
    this.logger.info(message, () => ({ ...messageContext, error }));
  }

  warn(message: string, messageContext?: object, error?: Error): void {
    this.logger.warn(message, () => ({ ...messageContext, error }));
  }

  error(message: string, messageContext?: object, error?: Error): void {
    this.logger.error(message, () => ({ ...messageContext, error }));
  }
}

export const logger = new LegacyLogger();

import { PropsWithChildren, ReactNode } from 'react';

import { useHasScroll } from '@/hooks/useElementSize';

import {
  Background,
  Root,
  Scrollable,
  ScrollableContent,
  Wordmark,
  WordmarkWrapper,
} from './RecordingLayoutRoot.styles';

export type RecordingLayoutRootProps = PropsWithChildren<{
  Header?: ReactNode;
  Footer?: ReactNode;
  dark?: boolean;
  centered?: boolean;
  showLogo?: boolean;
  animateLogo?: boolean;
}>;

export function RecordingLayoutRoot({
  dark = false,
  centered = false,
  showLogo,
  animateLogo,
  Header,
  Footer,
  children,
}: RecordingLayoutRootProps) {
  const [scrollRef, hasScroll] = useHasScroll();

  return (
    <Background data-dark={dark} data-has-scroll={hasScroll}>
      <Root>
        {showLogo && (
          <WordmarkWrapper
            data-dark={dark}
            initial={animateLogo ? { opacity: 0 } : false}
            animate={{ opacity: 1, transition: { duration: 0.5, ease: 'easeIn' } }}
          >
            <Wordmark color={dark ? 'neutral' : 'primary'} />
          </WordmarkWrapper>
        )}

        {Header}
        <Scrollable>
          <ScrollableContent ref={scrollRef} data-centered={centered}>
            {children}
          </ScrollableContent>
        </Scrollable>
        {Footer}
      </Root>
    </Background>
  );
}

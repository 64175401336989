import { useEffect, useMemo, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!ref) {
      return;
    }

    setSize({
      width: ref.offsetWidth,
      height: ref.offsetHeight,
    });

    const observer = new ResizeObserver(() => {
      setSize((oldSize) => {
        const newSize: Size = {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
        };
        if (newSize.width === oldSize.width && newSize.height === oldSize.height) {
          return oldSize;
        }
        return newSize;
      });
    });
    observer.observe(ref);
    return () => observer.disconnect();
  }, [ref]);

  return useMemo(() => [setRef, size], [setRef, size]);
}

export function useHasScroll<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, boolean, T | null] {
  const [ref, setRef] = useState<T | null>(null);
  const [hasScroll, setHasScroll] = useState<boolean>(false);

  useEffect(() => {
    if (!ref) {
      return;
    }

    const updateHasScroll = () => {
      setHasScroll(ref.scrollHeight > ref.clientHeight);
    };

    updateHasScroll();

    const resizeObserver = new ResizeObserver(updateHasScroll);
    resizeObserver.observe(ref);

    const mutationObserver = new MutationObserver(updateHasScroll);
    mutationObserver.observe(ref, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [ref]);

  return useMemo(() => [setRef, hasScroll, ref], [setRef, hasScroll, ref]);
}

import { PropsWithChildren } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faGear } from '@fortawesome/pro-solid-svg-icons';

import { RMStepper } from '@/components/RMStepper/RMStepper.tsx';
import { RMText } from '@/components/RMText/RMText';
import { useMatchMedia } from '@/hooks/useMatchMedia.ts';

import { BackButton, Header, SettingsButton, StepperWrapper, TitleWrapper } from './RecordingLayoutHeader.styles';

export type RecordingLayoutHeaderProps = PropsWithChildren<{
  step: 'hidden' | 'setup' | 'recording' | 'review';
  title?: string | null;
  hideSeparator?: boolean;
  transparentStepper?: boolean;
  onBack?: (() => void) | null;
  onChangeSettings?: (() => void) | null;
}>;

const STEP_INDEX = {
  setup: 0,
  recording: 1,
  review: 2,
} as const;

export function RecordingLayoutHeader({
  step,
  title,
  hideSeparator = false,
  transparentStepper = false,
  onBack,
  onChangeSettings,
  children,
}: RecordingLayoutHeaderProps) {
  const smallWidthPage = useMatchMedia('(max-width: 250px)');

  return (
    <Header data-hide-separator={hideSeparator}>
      {step !== 'hidden' && (
        <StepperWrapper data-transparent={transparentStepper}>
          <RMStepper
            layout={smallWidthPage ? 'progress' : 'stepper'}
            activeStepIndex={STEP_INDEX[step]}
            steps={[{ label: 'Setup' }, { label: 'Record' }, { label: 'Review' }]}
          />
        </StepperWrapper>
      )}

      {(title != null || onBack != null) && (
        <TitleWrapper data-settings-button={onChangeSettings != null}>
          {onBack != null && (
            <BackButton icon={faChevronLeft} size="xl" tooltip={null} backgroundColor="transparent" onClick={onBack} />
          )}
          {title != null && (
            <RMText type="serif" size="l" color="on-surface-primary" align="center">
              {title}
            </RMText>
          )}
          {onChangeSettings != null && (
            <SettingsButton
              icon={faGear}
              size="xl"
              tooltip={null}
              backgroundColor="transparent"
              color="neutral"
              onClick={onChangeSettings}
            />
          )}
        </TitleWrapper>
      )}

      {children}
    </Header>
  );
}

import { UserOnboardingActionType } from '@remento/types/user';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { AuthProvider, UserAnalyticsService } from './user-analytics.types.js';

export class UserAnalyticsServiceImpl implements UserAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onSignIn(provider: AuthProvider | null, isNewUser: boolean): void {
    if (isNewUser) {
      this.browserAnalytics.track(Events.UserSignUp, { provider });
    } else {
      this.browserAnalytics.track(Events.UserSignIn, { provider });
    }
  }

  onSignOut(): void {
    this.browserAnalytics.track(Events.UserSignOut);
  }

  onOnboardingProgress(step: UserOnboardingActionType): void {
    this.browserAnalytics.track(Events.UserOnboardingProgress, { step });
  }
}

import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  faBook as faBookOutline,
  faClock as faClockOutline,
  faGear as faGearOutline,
  faGift as faGiftOutline,
  faRectangleHistory as faRectangleHistoryOutline,
  faShare as faShareOutline,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBook as faBookSolid,
  faClock as faClockSolid,
  faGear as faGearSolid,
  faGift as faGiftSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faShare as faShareSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { AclGroupRole } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';
import { NotFoundError } from '@remento/types/error';
import { notNull } from '@remento/utils/array/notNull';

import { useMatchMedia } from '@/hooks/useMatchMedia';
import { showProjectPaywall } from '@/modules/paywall';
import { isPersonSubscriptionActive } from '@/modules/project-checkout/hooks/useStorytellerOptions';
import { useOpenReferralDialog, useOpenReferralDialogUrl } from '@/modules/referral/referral.hooks.js';
import {
  getAccountSettingsPath,
  getBookOrderCoverDesignPath,
  getBookOrderFinalizePath,
  getBookOrderPreviewPath,
  getBookOrderQuantityPath,
  getBookOrderStoriesPath,
  getCheckoutPath,
  getNewProjectPath,
  getProjectPromptsPath,
  getProjectSettingsPath,
  getStoriesPath,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useProjectBooksQuery } from '@/services/api/book';
import { usePersonAvatarUrl, usePersonInitials, usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';

import { Navbar } from '../components/Navbar/index.js';
import { StyledNavbarRoot } from '../components/Navbar/NavbarRoot.styles.js';
import { useNavbarStore } from '../states/navbar.state.context.js';
import {
  closeNavbar,
  getNavbarProjectId,
  NavbarStatus,
  setNavbarProjectId,
  setNavbarStatus,
  useNavbarProjectId,
  useNavbarStatus,
} from '../states/navbar.state.js';

import { ProjectSelectContainer } from './ProjectSelect.container.js';

const ADMIN_ROLES = [AclGroupRole.ADMIN, AclGroupRole.OWNER];

export function NavbarContainer() {
  const navigate = useNavigate();

  // Services
  const { webappAnalyticsService, personCacheService, aclCacheService, userCacheService, projectCacheService } =
    useServices();
  const mobile = useMatchMedia('(max-width: 768px)');

  // State
  const navbarStore = useNavbarStore();
  const navbarProjectId = useNavbarProjectId(navbarStore);
  const navbarStatus = useNavbarStatus(navbarStore);

  // Entities - Current user
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const personInitials = usePersonInitials(personQuery.data);
  const personAvatarUrl = usePersonAvatarUrl(personQuery.data);
  const userEmail = useMemo(() => {
    if (!user) {
      return;
    }
    return user.communicationChannels.email ?? null;
  }, [user]);

  // Entities - Current project
  const projectQuery = useProjectQuery(navbarProjectId);
  const projectRoles = useCurrentUserAclRoles(projectQuery.data?.acl);
  const isAdmin = hasRole(ADMIN_ROLES, projectRoles ?? []);

  const projectBooksQuery = useProjectBooksQuery(navbarProjectId);
  const bookId = projectBooksQuery.data?.[0];

  // Set the initial projectId id
  useEffect(() => {
    if (getNavbarProjectId(navbarStore) !== null) {
      return;
    }
    aclCacheService.getCurrentUserAclGroupMembers().then(async (groupMembersIds) => {
      if (getNavbarProjectId(navbarStore) !== null) {
        return;
      }

      const groupMembers = await Promise.all(
        groupMembersIds.map((groupMemberId) => aclCacheService.getAclGroupMember(groupMemberId)),
      );
      const firstProjectGroupMember = groupMembers
        .filter(notNull)
        .find((groupMember) => groupMember.groupType === EntityType.PROJECT);
      setNavbarProjectId(navbarStore, firstProjectGroupMember?.groupMetadata?.projectId ?? null);
    });
  }, [aclCacheService, navbarStore, personCacheService]);

  const handleClose = useCallback(() => {
    closeNavbar(navbarStore);
  }, [navbarStore]);

  const handleItemClick = useCallback(
    (action: () => void) => {
      action();
      if (mobile) {
        handleClose();
      }
    },
    [handleClose, mobile],
  );

  const handleChangeStatus = useCallback(
    (newStatus: NavbarStatus) => {
      setNavbarStatus(navbarStore, newStatus);
    },
    [navbarStore],
  );

  const handleAddProject = useCallback(async () => {
    if (user == null) {
      return;
    }

    if (user.storytellers.length == 0) {
      navigate(getCheckoutPath());
      return;
    }

    // Check if the user have at least one storyteller with a valid subscription.
    // We don't load all the data in a hook to avoid doing a lot of requests
    // every time the user access the app.
    const [projectsIds, _] = await Promise.all([
      await projectCacheService.getProjects(),
      await userCacheService.loadUserStorytellers(),
    ]);
    const projects = await Promise.all(
      projectsIds.map(async (id) => {
        const project = await projectCacheService.getProject(id);
        if (project == null) {
          throw new NotFoundError('owner-person-not-found', {
            origin: 'entity',
            entityType: EntityType.PROJECT,
            entityId: id,
          });
        }
        return project;
      }),
    );

    const storytellers = await Promise.all(
      user.storytellers.map(async (s) => {
        const ownerPerson = await personCacheService.getPerson(s.subscriptionOwnerPersonId);
        if (ownerPerson == null) {
          throw new NotFoundError('owner-person-not-found', {
            origin: 'entity',
            entityType: EntityType.PERSON,
            entityId: s.subscriptionOwnerPersonId,
          });
        }
        const isValid = isPersonSubscriptionActive(ownerPerson, projects);
        if (isValid) {
          return s;
        }
        return null;
      }),
    );
    const validStorytellers = storytellers.filter(notNull);

    if (validStorytellers.length == 0) {
      // Select a project to show the paywall.
      // Use the first project that user is owner of, or
      // if the user is just a storyteller/collaborator, use the first project.
      const project = projects.find((p) => user.refIds.includes(p.ownerUserId)) ?? projects[0];
      showProjectPaywall(project.id);
      return;
    }

    navigate(getNewProjectPath());
  }, [navigate, personCacheService, projectCacheService, user, userCacheService]);

  // Referral state
  const referralUrl = useOpenReferralDialogUrl();
  const handleReferral = useOpenReferralDialog();

  // Keep the navbar always open in desktop
  useEffect(() => {
    if (!mobile) {
      setNavbarStatus(navbarStore, 'open');
    }
  }, [mobile, navbarStore]);

  if (!user || !navbarProjectId) {
    return <StyledNavbarRoot />;
  }

  return (
    <Navbar.Root
      status={navbarStatus}
      Items={
        <>
          <Navbar.Item
            id="navbar-stories-link"
            text="Stories"
            path={getStoriesPath(navbarProjectId)}
            activeIcon={faRectangleHistorySolid}
            inactiveIcon={faRectangleHistoryOutline}
            onClick={() => handleItemClick(() => webappAnalyticsService.onStoriesPressed())}
          />

          <Navbar.Item
            text="Prompts"
            path={getProjectPromptsPath(navbarProjectId)}
            activeIcon={faClockSolid}
            inactiveIcon={faClockOutline}
            onClick={() => handleItemClick(() => webappAnalyticsService.onUpcomingPromptsPressed())}
          />
          {isAdmin && (
            <Navbar.Item
              text="Project settings"
              path={getProjectSettingsPath(navbarProjectId)}
              activeIcon={faGearSolid}
              inactiveIcon={faGearOutline}
              onClick={() => handleItemClick(() => webappAnalyticsService.onProjectSettingsPressed())}
            />
          )}

          <Navbar.Item
            text="Order books"
            path={getBookOrderCoverDesignPath(navbarProjectId, bookId ?? '')}
            pathAliases={[
              getBookOrderStoriesPath(navbarProjectId, bookId ?? ''),
              getBookOrderPreviewPath(navbarProjectId, bookId ?? ''),
              getBookOrderQuantityPath(navbarProjectId, bookId ?? ''),
              getBookOrderFinalizePath(navbarProjectId, bookId ?? ''),
            ]}
            activeIcon={faBookSolid}
            inactiveIcon={faBookOutline}
            onClick={() => handleItemClick(() => webappAnalyticsService.onOrderBooksPressed())}
          />

          <Navbar.Divider />

          <Navbar.Item
            text="Refer Remento"
            path={referralUrl}
            activeIcon={faShareSolid}
            inactiveIcon={faShareOutline}
            onClick={() => handleItemClick(handleReferral)}
          />
          <Navbar.Item
            text="Gift Remento"
            path={getCheckoutPath()}
            activeIcon={faGiftSolid}
            inactiveIcon={faGiftOutline}
            onClick={() => handleItemClick(() => webappAnalyticsService.onGiftPressed())}
          />
        </>
      }
      Account={
        <Navbar.Account
          to={getAccountSettingsPath()}
          onClick={() => handleItemClick(() => webappAnalyticsService.onMyAccountPressed())}
          userEmail={userEmail ?? 'N/A'}
          userInitials={personInitials}
          userAvatarUrl={personAvatarUrl}
        />
      }
      ProjectSelect={<ProjectSelectContainer onAddProject={handleAddProject} />}
      onClose={handleClose}
      onChangeStatus={handleChangeStatus}
      onAddProject={handleAddProject}
    />
  );
}

import { RMLoader } from '@/components/RMLoader/RMLoader.tsx';

import { RecordingLayout } from '../../layouts/RecordingLayout';

export function RecordingIntroLoader() {
  return (
    <RecordingLayout.Root dark>
      <RMLoader center light />
    </RecordingLayout.Root>
  );
}

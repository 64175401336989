import 'react-toastify/dist/ReactToastify.css';
import '@vidstack/react/player/styles/base.css';

import { useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider as WrapBalancerProvider } from 'react-wrap-balancer';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';

import FaviconDevSrc from './assets/dev-favicon.svg';
import FaviconSrc from './assets/favicon.svg';
import { NavbarStoreProvider } from './modules/navbar/states/navbar.state.context.js';
import { createNavbarStore } from './modules/navbar/states/navbar.state.js';
import { routes } from './routes.js';
import { appStyles } from './styles/index.js';

const basename = import.meta.env.VITE_PATH_BASENAME ?? '/';
const router = createBrowserRouter(routes, {
  basename,
});

// Redirects from / routes to the prefixed route
// https://github.com/remix-run/react-router/issues/8427
if (window.location.pathname.startsWith(basename) === false) {
  window.location.replace(basename + window.location.pathname + window.location.search);
}

export function App() {
  const navbarStore = useMemo(() => createNavbarStore(), []);

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="icon" href={import.meta.env.VITE_ENV === 'production' ? FaviconSrc : FaviconDevSrc} />
        <style>{appStyles}</style>
      </Helmet>
      <Container>
        <WrapBalancerProvider>
          <NavbarStoreProvider store={navbarStore}>
            <RouterProvider router={router} />
          </NavbarStoreProvider>
          <ToastContainer enableMultiContainer containerId="root-toast" />
          <ToastContainer enableMultiContainer containerId="full-screen-toast" className={fullScreenToastClassName} />
        </WrapBalancerProvider>
      </Container>
    </HelmetProvider>
  );
}

const fullScreenToastClassName = css`
  width: 100%;
  text-align: center;
  padding: 0;

  [data-mobile]:not([data-mobile='true']) & {
    top: 0;
  }

  body[data-mobile='true'] & {
    bottom: 0;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

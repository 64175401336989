import { ComponentProps, MouseEvent, useCallback, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import {
  affirmativeBackgroundVariant,
  Button,
  ButtonContent,
  dangerBackgroundVariant,
  dangerColorVariant,
  dashedBackgroundVariant,
  extraSmallSizeVariant,
  fullWidthVariant,
  gradientBackgroundVariant,
  largeSizeVariant,
  massiveSizeVariant,
  mediumSizeVariant,
  neutralBackgroundVariant,
  neutralOnInverseBackgroundVariant,
  outlinedBackgroundVariant,
  primaryBackgroundVariant,
  recordingBackgroundVariant,
  recordingColorVariant,
  secondaryColorVariant,
  smallSizeVariant,
  surfaceColorVariant,
  transparentBackgroundVariant,
  transparentNeutralBackgroundVariant,
  whiteColorVariant,
} from './RMButton.styles';
import { RMButtonLoader } from './RMButtonLoader';

// @info:
// - none: uses darken-primary on hover/active
// - transparent: uses darken-neutral on hover/active
type RMButtonBackgroundVariant =
  | 'none'
  | 'outlined'
  | 'neutral'
  | 'neutral-on-inverse'
  | 'primary'
  | 'gradient'
  | 'danger'
  | 'recording'
  | 'dashed'
  | 'affirmative'
  | 'transparent';
type RMButtonSizeVariant = 'extra-small' | 'small' | 'medium' | 'large' | 'massive';
type RMButtonColorVariant = 'default' | 'recording' | 'white' | 'surface' | 'secondary' | 'danger';

const backgroundVariants: Record<RMButtonBackgroundVariant, string> = {
  none: transparentBackgroundVariant,
  outlined: outlinedBackgroundVariant,
  neutral: neutralBackgroundVariant,
  'neutral-on-inverse': neutralOnInverseBackgroundVariant,
  primary: primaryBackgroundVariant,
  gradient: gradientBackgroundVariant,
  danger: dangerBackgroundVariant,
  recording: recordingBackgroundVariant,
  dashed: dashedBackgroundVariant,
  affirmative: affirmativeBackgroundVariant,
  transparent: transparentNeutralBackgroundVariant,
};

const sizeVariants: Record<RMButtonSizeVariant, string> = {
  'extra-small': extraSmallSizeVariant,
  small: smallSizeVariant,
  medium: mediumSizeVariant,
  large: largeSizeVariant,
  massive: massiveSizeVariant,
};

const colorVariants: Record<RMButtonColorVariant, string | null> = {
  default: null,
  recording: recordingColorVariant,
  white: whiteColorVariant,
  surface: surfaceColorVariant,
  secondary: secondaryColorVariant,
  danger: dangerColorVariant,
};

interface RMButtonProps extends ComponentProps<'button'> {
  /**
   * The color variant of the button background
   */
  background?: RMButtonBackgroundVariant;
  /**
   * The size variant of the button
   */
  size?: RMButtonSizeVariant;
  iconSize?: 'medium' | 'large' | 'extra-large';
  /**
   * The text color
   */
  color?: RMButtonColorVariant;
  /**
   * A component to display to the left of the button content
   */
  leftIcon?: IconDefinition | string | null;
  /**
   * A component to display to the right of the button content
   */
  rightIcon?: IconDefinition | string | null;
  fullWidth?: boolean;
  minWidth?: 'none' | 'fit-content';
  loading?: boolean;
  autoLoading?: boolean;
}

/**
 * Displays a button
 */
export function RMButton({
  children,
  background = 'neutral',
  size = 'medium',
  iconSize = 'medium',
  color = 'default',
  leftIcon,
  rightIcon,
  fullWidth = false,
  minWidth = 'fit-content',
  loading = false,
  autoLoading = false,
  className,
  onClick,
  disabled,
  ...props
}: RMButtonProps) {
  const [runningOnClick, setRunningOnClick] = useState(false);
  const showLoader = loading || (autoLoading && runningOnClick);

  const handleOnClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      if (!loading) {
        try {
          setRunningOnClick(true);
          await onClick?.(event);
        } finally {
          setRunningOnClick(false);
        }
      }
    },
    [loading, onClick],
  );

  return (
    <Button
      className={clsx(
        backgroundVariants[background],
        sizeVariants[size],
        colorVariants[color],
        fullWidth && fullWidthVariant,
        className,
      )}
      data-min-width={minWidth}
      onClick={handleOnClick}
      disabled={disabled || showLoader}
      data-icon-size={iconSize}
      {...props}
    >
      <ButtonContent hidden={showLoader}>
        {leftIcon && typeof leftIcon !== 'string' && <FontAwesomeIcon icon={leftIcon} />}
        {leftIcon && typeof leftIcon === 'string' && <img src={leftIcon} alt="utton icon" aria-hidden="true" />}
        <span>{children}</span>
        {rightIcon && typeof rightIcon !== 'string' && <FontAwesomeIcon icon={rightIcon} />}
        {rightIcon && typeof rightIcon === 'string' && <img src={rightIcon} alt="utton icon" aria-hidden="true" />}
      </ButtonContent>
      {showLoader && <RMButtonLoader />}
    </Button>
  );
}

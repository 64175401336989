import 'react-international-phone/style.css';

import { PhoneInput } from 'react-international-phone';

import {
  nativeClassName,
  nativeInputClassName,
  nativeSelectorButtonClassName,
  nativeSelectorButtonContentWrapperClassName,
  nativeSelectorDropdownClassName,
  nativeSelectorDropdownListItemClassName,
  PhoneInputError,
  PhoneInputLabel,
  PhoneInputWrapper,
} from './RMPhoneInput.styles';

export interface PhoneNumber {
  countryCode: string;
  number: string;
}

export interface RMPhoneInputProps {
  label?: string;
  value?: PhoneNumber | null;
  error?: string | null;
  disabled?: boolean;
  dropdownPosition?: 'top' | 'default';
  onChange?: (value: PhoneNumber) => void;
  onBlur?: () => void;
}

export function RMPhoneInput({ label, value, error, disabled, dropdownPosition, onChange, onBlur }: RMPhoneInputProps) {
  const stringValue = value == null ? undefined : value.countryCode + value.number;

  return (
    <PhoneInputWrapper data-disabled={disabled} data-dropdown-position={dropdownPosition}>
      {label && <PhoneInputLabel>{label}</PhoneInputLabel>}
      <PhoneInput
        inputProps={{
          'aria-label': label,
        }}
        defaultCountry="us"
        disabled={disabled}
        value={stringValue}
        onChange={(phone, { country }) => {
          if (phone.length === 0) {
            onChange?.({ countryCode: '', number: '' });
            return;
          }

          // If we trigger a onChange when the value is just the + sign, it
          // may cause some weird behavior and not let the user clean the country code.
          // We can safely ignore this if the number is empty.
          if (phone === '+' && value?.number.length === 0) {
            return;
          }

          // If the input starts with the country code, that means
          // that the user is done typing the country code and we can start
          // parsing the phone number.
          if (phone.startsWith(`+${country.dialCode}`)) {
            const newValue = {
              countryCode: country.dialCode,
              number: phone.substring(country.dialCode.length + 1),
            };
            if (newValue.countryCode === value?.countryCode && newValue.number === value.number) {
              // We don't need to trigger this if the values are equal.
              return;
            }
            onChange?.(newValue);
            return;
          }
          // Otherwise, the guessed country may not be correct yet, and we need to send the input
          // as the country code.
          onChange?.({
            countryCode: phone.substring(1),
            number: '',
          });
        }}
        onBlur={onBlur}
        className={nativeClassName}
        inputClassName={nativeInputClassName}
        countrySelectorStyleProps={{
          dropdownStyleProps: {
            className: nativeSelectorDropdownClassName,
            listItemClassName: nativeSelectorDropdownListItemClassName,
          },
          buttonClassName: nativeSelectorButtonClassName,
          buttonContentWrapperClassName: nativeSelectorButtonContentWrapperClassName,
        }}
      />
      {error && <PhoneInputError>{error}</PhoneInputError>}
    </PhoneInputWrapper>
  );
}

import { ReactNode, useCallback } from 'react';
import { createStore, useStore } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { secureUuid } from '@/utils/uuid';

export interface Dialog {
  id: string;
  content: ReactNode;
}

export type DialogRenderFn = (onClose: () => void) => ReactNode;

interface DialogManagerState {
  dialogs: Dialog[];
}

const store = createStore(subscribeWithSelector<DialogManagerState>(() => ({ dialogs: [] })));

function closeDialog(id: string): void {
  store.setState((state) => {
    return {
      ...state,
      dialogs: state.dialogs.filter((d) => d.id !== id),
    };
  }, true);
}

function isDialogOpen(id: string): boolean {
  return store.getState().dialogs.some((d) => d.id == id);
}

async function waitUntilDialogIsClosed(id: string): Promise<void> {
  if (!isDialogOpen(id)) {
    // The dialog is already closed
    return;
  }
  return new Promise((resolve) => {
    const unsubscribe = store.subscribe(() => {
      if (isDialogOpen(id)) {
        return;
      }
      unsubscribe();
      resolve();
    });
  });
}

export async function openDialog(renderFn: DialogRenderFn): Promise<void> {
  const id = secureUuid();
  store.setState((state) => {
    return {
      ...state,
      dialogs: [
        ...state.dialogs,
        {
          id,
          content: renderFn(() => closeDialog(id)),
        },
      ],
    };
  }, true);
  return waitUntilDialogIsClosed(id);
}

export function useDialogs(): Dialog[] {
  return useStore(
    store,
    useCallback((s) => s.dialogs, []),
  );
}

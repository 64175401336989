import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: var(--spacing-md) var(--spacing-md);

  [data-has-scroll='true'] &[data-hide-separator='false'] {
    border-bottom: 1px solid var(--border-weak);
  }

  body[data-mobile='false'] & {
    padding-top: 28px;
    padding-bottom: 19px;
  }

  > * {
    flex-shrink: 0;
  }
`;

export const StepperWrapper = styled.div`
  width: 100%;
  transition: opacity 0.25s ease-in-out;
  padding-bottom: var(--spacing-md);

  body[data-mobile='false'] & {
    padding-bottom: 28px;
  }

  &[data-transparent='true'] {
    opacity: 0;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  /* We need these weird spacings so the button is at the left and the text is center aligned*/
  width: calc(100% - 32px);
  margin-left: 8px;

  &[data-settings-button='true'] {
    margin-right: 8px;
    width: calc(100% - 52px);
  }

  body[data-mobile='false'] & {
    margin-top: 19px;
  }
`;

export const BackButton = styled(RMIconButton)`
  left: -20px;
  position: absolute;
  margin-left: calc(var(--spacing-md) * -1);

  ${TitleWrapper}[data-settings-button='true'] & {
    left: -28px;
  }
`;

export const SettingsButton = styled(RMIconButton)`
  right: -24px;
  position: absolute;
  margin-right: calc(var(--spacing-md) * -1);
`;

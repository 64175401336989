import { ReactionSentiment } from '@remento/types/reaction';
import { StoryDataType } from '@remento/types/story';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import {
  ReactionSentimentDescription,
  ReactionSource,
  StoriesAction,
  StoryPage,
  StoryShareTarget,
  StoryType,
  StoryViewerAnalytics,
  StoryViewerSummaryAction,
} from './story-viewer-analytics.types.js';

export class DefaultStoryViewerAnalytics implements StoryViewerAnalytics {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onStoryArrived(): void {
    this.browserAnalytics.track(Events.StoryViewerArrive, {});
  }

  onStoryWatched(duration: number, type: StoryType): void {
    this.browserAnalytics.track(Events.StoryViewerWatch, {
      duration,
      type,
    });
  }

  onStoryShared(page: StoryPage, target: StoryShareTarget): void {
    this.browserAnalytics.track(Events.StoryViewerShare, { page, target });
  }

  onStoryMuted(type: 'mute-on' | 'mute-off'): void {
    this.browserAnalytics.track(Events.StoryViewerMute, {
      type,
    });
  }

  onStoryLiked(): void {
    this.browserAnalytics.track(Events.StoryViewerLike, {});
  }

  onStoryDownloaded(type: StoryType): void {
    this.browserAnalytics.track(Events.StoryViewerDownload, {
      type,
    });
  }

  onStorySummaryActionPerformed(cta: StoryViewerSummaryAction): void {
    this.browserAnalytics.track(Events.StoryViewerSummaryAction, {
      cta,
    });
  }

  onStoryEditModeArrived(): void {
    this.browserAnalytics.track(Events.StoryViewerEditModeArrive, {});
  }

  onStoryRegenerateOpened(): void {
    this.browserAnalytics.track(Events.StoryViewerRegenerateOpen, {});
  }

  onStorySummaryUpdated(type: StoryDataType): void {
    this.browserAnalytics.track(Events.StoryViewerSummaryUpdate, { type });
  }

  onStoryPhotoAdded(): void {
    this.browserAnalytics.track(Events.StoryViewerPhotoAdd, {});
  }

  onStoryPhotoUpdated(): void {
    this.browserAnalytics.track(Events.StoryViewerPhotoUpdate, {});
  }

  onStoryPhotoRemoved(): void {
    this.browserAnalytics.track(Events.StoryViewerPhotoRemove, {});
  }

  onStoriesArrived(): void {
    this.browserAnalytics.track(Events.StoriesViewerArrive, {});
  }

  onStoriesOpened(): void {
    this.browserAnalytics.track(Events.StoriesViewerOpen, {});
  }

  onStoriesActionPerformed(action: StoriesAction): void {
    this.browserAnalytics.track(Events.StoriesViewerAction, {
      action,
    });
  }

  onReactionArrived(reactionSource: ReactionSource): void {
    this.browserAnalytics.track(Events.ReactionArrive, {
      reactionSource,
    });
  }

  onReactionSent(page: StoryPage, emoji: ReactionSentiment | null, textCustomized: boolean): void {
    const emojiDescription = emoji ? ReactionSentimentDescription[emoji] : null;

    this.browserAnalytics.track(Events.ReactionSent, {
      page,
      emoji: emojiDescription,
      textCustomized,
    });
  }
}

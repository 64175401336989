import { ABTestService } from '@remento/ab-testing/ab-test.types';
import { BOOK_AB_TEST_RULES, BookExperiments } from '@remento/book-ab-testing';
import { createLogger } from '@remento/logger';
import { User } from '@remento/types/user';
import { AnalyticsUserTraits } from '@remento/web-analytics-client/@types';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';
import { AnalyticsServiceClient } from '@remento/web-analytics-client/analytics-service-client';
import { IDRepository } from '@remento/web-analytics-client/id-repository';
import { IDServiceClient } from '@remento/web-analytics-client/id-service-client';

import { UserTraits } from '@/services/analytics/analytics.types';
import { CheckoutAnalyticsService, DefaultCheckoutAnalyticsService } from '@/services/analytics/checkout-analytics';
import {
  CollaborationAnalyticsService,
  DefaultCollaborationAnalyticsService,
} from '@/services/analytics/collaboration-analytics';
import { DatadogTracingService } from '@/services/analytics/datadog/datadog-tracing.types';
import { IntercomService } from '@/services/analytics/intercom/intercom.types';
import {
  DefaultOnboardingAnalyticsService,
  OnboardingAnalyticsService,
} from '@/services/analytics/onboarding-analytics';
import { DefaultPollAnalyticsService, PollAnalyticsService } from '@/services/analytics/poll-analytics';
import { DefaultProjectAnalyticsService, ProjectAnalyticsService } from '@/services/analytics/project-analytics';
import { DefaultPromptAnalyticsService } from '@/services/analytics/prompt-analytics/prompt-analytics.service';
import { PromptAnalyticsService } from '@/services/analytics/prompt-analytics/prompt-analytics.types';
import {
  DefaultQuestionnaireAnalyticsService,
  QuestionnaireAnalyticsService,
} from '@/services/analytics/questionnaire-analytics';
import { DefaultReferralAnalyticsService, ReferralAnalyticsService } from '@/services/analytics/referral-analytics';
import { DefaultStoryViewerAnalytics } from '@/services/analytics/story-viewer-analytics/story-viewer-analytics.service';
import { StoryViewerAnalytics } from '@/services/analytics/story-viewer-analytics/story-viewer-analytics.types';
import { DefaultStorytellingAnalyticsService } from '@/services/analytics/storytelling-analytics/storytelling-analytics.service';
import { StorytellingAnalyticsService } from '@/services/analytics/storytelling-analytics/storytelling-analytics.types';
import { DefaultSurveyAnalyticsService, SurveyAnalyticsService } from '@/services/analytics/survey-analytics';
import { UserAnalyticsServiceImpl } from '@/services/analytics/user-analytics/user-analytics.service';
import { UserAnalyticsService } from '@/services/analytics/user-analytics/user-analytics.types';
import { WebappAnalyticsServiceImpl } from '@/services/analytics/webapp-analytics/webapp-analytics.service';
import { WebappAnalyticsService } from '@/services/analytics/webapp-analytics/webapp-analytics.types';
import { AuthService, AuthStateType } from '@/services/api/auth/auth.types';
import { PersonCacheService } from '@/services/api/person';
import { UserService } from '@/services/api/user/user.types';

import { DefaultIntercomService } from './services/analytics/intercom/intercom.service.js';

const logger = createLogger('analytics-services-init');

export interface AnalyticsServicesInstances {
  browserAnalytics: BrowserAnalytics<UserTraits>;
  webappAnalyticsService: WebappAnalyticsService;
  promptAnalyticsService: PromptAnalyticsService;
  storyViewerAnalyticsService: StoryViewerAnalytics;
  storytellingAnalyticsService: StorytellingAnalyticsService;
  checkoutAnalyticsService: CheckoutAnalyticsService;
  onboardingAnalyticsService: OnboardingAnalyticsService;
  collaborationAnalyticsService: CollaborationAnalyticsService;
  pollAnalyticsService: PollAnalyticsService;
  questionnaireAnalyticsService: QuestionnaireAnalyticsService;
  projectAnalyticsService: ProjectAnalyticsService;
  surveyAnalyticsService: SurveyAnalyticsService;
  referralAnalyticsService: ReferralAnalyticsService;
  userAnalyticsService: UserAnalyticsService;
  intercomService?: IntercomService;
}

export async function createAnalyticsServicesInstances(
  authService: AuthService,
  userService: UserService,
  personCacheService: PersonCacheService,
  datadogTracingService: DatadogTracingService,
  abTestService: ABTestService<BookExperiments, typeof BOOK_AB_TEST_RULES>,
): Promise<AnalyticsServicesInstances> {
  const getUserAnalyticsTraits = async (user: User): Promise<AnalyticsUserTraits> => {
    const person = await personCacheService.getPerson(user.personId);
    return {
      appBookUserId: user.id,
      email: user.communicationChannels.email,
      phone:
        user.communicationChannels.phone != null
          ? `+${user.communicationChannels.phone.countryCode}${user.communicationChannels.phone.number}`
          : null,
      name: person?.name?.full,
      firstName: person?.name?.first,
      lastName: person?.name?.last,
    };
  };

  const currentUser = await userService.getUser();
  const analyticsLogger = createLogger('analytics-client');
  const idRepository = new IDRepository(import.meta.env.VITE_ANALYTICS_ROOT_DOMAIN);
  const idService = new IDServiceClient(import.meta.env.VITE_ANALYTICS_API_URL);
  const analyticsService = new AnalyticsServiceClient(import.meta.env.VITE_ANALYTICS_API_URL);

  // If there's a signed in user, we should always save it to the ID repository.
  if (currentUser?.ruid != null) {
    const currentAnalyticsUser = idRepository.get();
    if (currentAnalyticsUser.type != 'identified' || currentAnalyticsUser.id != currentUser.ruid)
      idRepository.save({
        id: currentUser.ruid,
        type: 'identified',
        traits: {},
        anonymousId:
          currentAnalyticsUser.type == 'anonymous' ? currentAnalyticsUser.id : currentAnalyticsUser.anonymousId,
        source: 'new',
      });
  }

  let analyticsUser = idRepository.get();

  // Always save new users
  if (analyticsUser.source === 'new') {
    analyticsUser = idRepository.save(analyticsUser);
  }

  // Always save the utm user in the session to prevent losing the user
  // when refreshing the page.
  if (analyticsUser.source === 'utm') {
    analyticsUser = idRepository.saveForSession(analyticsUser);
  }

  const browserAnalytics = new BrowserAnalytics(
    analyticsService,
    idService,
    abTestService,
    analyticsLogger,
    analyticsUser,
    {
      source: import.meta.env.VITE_ANALYTICS_SOURCE,
      segment: {
        key: import.meta.env.VITE_ANALYTICS_SEGMENT_KEY,
        apiHost: `${import.meta.env.VITE_ANALYTICS_SEGMENT_API_HOST}${import.meta.env.VITE_ANALYTICS_SEGMENT_API_PATH}`,
        cdnURL: `https://${import.meta.env.VITE_ANALYTICS_SEGMENT_CDN_HOST}${import.meta.env.VITE_ANALYTICS_SEGMENT_CDN_PATH}`,
      },
    },
  );

  // Init all custom analytics services
  const webappAnalyticsService = new WebappAnalyticsServiceImpl(browserAnalytics);
  const userAnalyticsService = new UserAnalyticsServiceImpl(browserAnalytics);
  const promptAnalyticsService = new DefaultPromptAnalyticsService(browserAnalytics);
  const storyViewerAnalyticsService = new DefaultStoryViewerAnalytics(browserAnalytics);
  const storytellingAnalyticsService = new DefaultStorytellingAnalyticsService(browserAnalytics);
  const checkoutAnalyticsService = new DefaultCheckoutAnalyticsService(browserAnalytics);
  const onboardingAnalyticsService = new DefaultOnboardingAnalyticsService(browserAnalytics);
  const collaborationAnalyticsService = new DefaultCollaborationAnalyticsService(browserAnalytics);
  const pollAnalyticsService = new DefaultPollAnalyticsService(browserAnalytics);
  const questionnaireAnalyticsService = new DefaultQuestionnaireAnalyticsService(browserAnalytics);
  const projectAnalyticsService = new DefaultProjectAnalyticsService(browserAnalytics);
  const surveyAnalyticsService = new DefaultSurveyAnalyticsService(browserAnalytics);
  const referralAnalyticsService = new DefaultReferralAnalyticsService(browserAnalytics);

  // Init the intercom service
  let intercomService: IntercomService | undefined;
  const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;
  if (intercomAppId) {
    intercomService = new DefaultIntercomService(intercomAppId, analyticsUser);
  }

  // Identify the user in datadog and listen to error events
  datadogTracingService.identify(analyticsUser);
  datadogTracingService.onError((errorUrl) => {
    webappAnalyticsService.onError(errorUrl);
  });

  // Pass all the analytics events to external services
  browserAnalytics.on('track', (event, properties) => {
    datadogTracingService.track(event, properties);
  });
  browserAnalytics.on('page', (page) => {
    datadogTracingService.page(page);
  });
  browserAnalytics.on('identify', (user) => {
    idRepository.save(user);
    datadogTracingService.identify(user);
    intercomService?.identify(user);
  });
  browserAnalytics.on('reset', (anonymousUser) => {
    idRepository.save(anonymousUser);
    datadogTracingService.reset(anonymousUser);
    intercomService?.reset();
  });

  // Call identify again when tue user changes.
  // This may happen because the user was updated or just signed in
  userService.onUserChanged(async (newUser) => {
    if (newUser == null) {
      return;
    }
    browserAnalytics
      .identify(
        { type: 'email', value: newUser.communicationChannels.email },
        'book-user',
        await getUserAnalyticsTraits(newUser),
      )
      .catch((error) => logger.error('IDENTIFY_FAILED', () => ({ error })));
  });

  // Reset the analytics client when the user signs out.
  // Also, trigger an event when the user signs in/out
  authService.onAuthChanged((authState) => {
    if (authState.type === AuthStateType.SignedOut) {
      userAnalyticsService.onSignOut();
      browserAnalytics.reset();
      return;
    }

    userAnalyticsService.onSignIn(authState.authProvider, authState.isNewUser);
  });

  return {
    browserAnalytics,
    webappAnalyticsService,
    promptAnalyticsService,
    storyViewerAnalyticsService,
    storytellingAnalyticsService,
    checkoutAnalyticsService,
    onboardingAnalyticsService,
    collaborationAnalyticsService,
    pollAnalyticsService,
    questionnaireAnalyticsService,
    projectAnalyticsService,
    surveyAnalyticsService,
    referralAnalyticsService,
    userAnalyticsService,
    intercomService,
  };
}

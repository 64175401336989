import { styled } from '@linaria/react';

export const Stepper = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  width: 100%;
`;

export const Step = styled.div`
  display: flex;
  flex-flow: column;
  gap: var(--spacing-sm);
  width: 100%;
  cursor: pointer;
  overflow: hidden;

  > label {
    text-transform: uppercase;
  }

  &[data-disabled='true'] {
    opacity: 0.6;
  }

  &[data-disabled='false'] {
    :hover {
      label {
        text-decoration: underline;
        color: var(--on-surface-secondary);
      }
    }
  }
`;

export const StepBar = styled.div<{ active: boolean }>`
  height: 0.5rem;
  border-radius: var(--radius-smooth);
  background-color: ${(props) => (props.active ? 'var(--border-primary)' : 'unset')};
  border: 1px solid var(--border-primary);
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  width: 100%;
  text-transform: uppercase;
`;

export const Progress = styled.progress`
  width: 100%;
  height: 6px;
  flex: 0 0 6px;
  appearance: none;
  -webkit-appearance: none;

  &::-webkit-progress-bar {
    background-color: transparent;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-smooth);
  }

  &::-webkit-progress-value {
    transition: width 250ms;
    background: var(--border-primary);
  }

  &::-moz-progress-bar {
    background: var(--border-primary);
    border: 1px solid var(--border-primary);
    border-radius: var(--radius-smooth);
  }
`;

import { StoryPerspectiveType } from '@remento/types/story';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Weekday } from '@/utils/tz';

import {  Events } from '../analytics.types';

import { ProjectAnalyticsService } from './project-analytics.types.js';

export class DefaultProjectAnalyticsService implements ProjectAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onProjectDefaultPerspectiveChanged(perspective: StoryPerspectiveType, origin: 'onboarding' | 'settings'): void {
    this.browserAnalytics.track(Events.ProjectDefaultPerspectiveChange, {
      perspective,
      origin,
    });
  }

  onProjectDefaultPerspectiveDialogViewed(): void {
    this.browserAnalytics.track(Events.ProjectDefaultPerspectiveDialogViewed);
  }

  onProjectDefaultNotificationsIntervalChanged(
    interval: string,
    rawTime: string,
    weekdays: Set<Weekday>,
    origin: 'onboarding' | 'settings',
  ) {
    const [hours, minutes] = rawTime.split(':').map((n) => parseInt(n, 10));
    const time = hours + minutes / 60;

    this.browserAnalytics.track(Events.ProjectDefaultNotificationIntervalChange, {
      sendOnTime: time,
      sendOnMondays: weekdays.has(Weekday.MONDAY),
      sendOnTuesdays: weekdays.has(Weekday.TUESDAY),
      sendOnWednesdays: weekdays.has(Weekday.WEDNESDAY),
      sendOnThursdays: weekdays.has(Weekday.THURSDAY),
      sendOnFridays: weekdays.has(Weekday.FRIDAY),
      sendOnSaturdays: weekdays.has(Weekday.SATURDAY),
      sendOnSundays: weekdays.has(Weekday.SUNDAY),
      sendOnDays: weekdays.size,
      interval,
      origin,
    });
  }
}

import { createLogger } from '@remento/logger';
import { notNull } from '@remento/utils/array/notNull';

import { LocalStoreRepository } from './local-store.types.js';

const logger = createLogger('local-session-store');
export class LocalSessionStoreRepository implements LocalStoreRepository {
  constructor(private permanentKeys: Set<string> = new Set<string>()) {}

  getItem<T = string>(key: string): T | null {
    const value = sessionStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  setItem<T = string>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(prefix?: string) {
    const allKeys = Object.keys(sessionStorage);
    const keysToDelete = allKeys
      .filter((key) => !this.permanentKeys.has(key))
      .filter((key) => !prefix || key.startsWith(prefix));
    keysToDelete.forEach((key) => {
      sessionStorage.removeItem(key);
    });
    logger.info('CLEAR', () => ({ keys: keysToDelete }));
  }

  keys(prefix?: string): string[] {
    let keys = Object.keys(sessionStorage);
    if (prefix) {
      keys = keys.filter((key) => key.startsWith(prefix));
    }
    return keys;
  }

  entries<T = string>(prefix?: string): [string, T][] {
    const keys = this.keys(prefix);
    return keys
      .map((key) => {
        try {
          return [key, this.getItem<T>(key) as T];
        } catch {
          return [key, sessionStorage.getItem(key)];
        }
      })
      .filter(notNull) as [string, T][];
  }
}

import { Fragment } from 'react';

import { useDialogs } from '../dialog-manager.js';

export function DialogManagerContainer() {
  const dialogs = useDialogs();

  if (dialogs.length == 0) {
    return null;
  }

  return (
    <>
      {dialogs.map((dialog) => (
        <Fragment key={dialog.id}>{dialog.content}</Fragment>
      ))}
    </>
  );
}

import { PropsWithChildren } from 'react';
import { faChevronDown, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as RadixSelect from '@radix-ui/react-select';

import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useIsTabletViewport } from '@/hooks/useIsMobileViewport';

import { SelectItem } from './ProjectSelectItem.styles.js';
import { Select, SelectContent, SelectInfo } from './ProjectSelectRoot.styles.js';

type ProjectSelectRootProps = PropsWithChildren<{
  mobile?: boolean;
  selectedId: string;
  selectedInitials: string;
  selectedPhotoUrl?: string | null;
  selectedName: string;
  selectedSubjectName: string | null;
  onChangeProject: (bookId: string) => void;
  onAddProject: () => void;
}>;

export function ProjectSelectRoot({
  mobile,
  selectedId,
  selectedInitials,
  selectedPhotoUrl,
  selectedName,
  selectedSubjectName,
  onChangeProject,
  onAddProject,
  children,
}: ProjectSelectRootProps) {
  const isTablet = useIsTabletViewport();

  return (
    <RadixSelect.Root
      value={selectedId}
      onValueChange={(value) => {
        if (value === 'add-storyteller') {
          onAddProject();
        } else {
          onChangeProject(value);
        }
      }}
    >
      <RMTooltip
        message={
          isTablet ? (
            <>
              <RMText type="sans" size="xxs" bold color="inverse-on-surface-primary">
                Project:
              </RMText>
              <br />
              <RMText type="sans" size="xxs" color="inverse-on-surface-primary">
                {selectedName}
              </RMText>
            </>
          ) : null
        }
      >
        <RadixSelect.Trigger asChild>
          <Select>
            <RMAvatar placeholder={selectedInitials} background="primary" shape="rounded" src={selectedPhotoUrl} />

            {!isTablet && (
              <>
                <SelectInfo>
                  <RMText type="sans" size="xxs" bold color="inverse-on-surface-secondary">
                    {selectedSubjectName}
                  </RMText>

                  <RMText type="sans" size="xs" bold color="inverse-on-surface-primary">
                    {selectedName}
                  </RMText>
                </SelectInfo>

                <FontAwesomeIcon icon={faChevronDown} size="sm" color="var(--inverse-on-surface-primary)" />
              </>
            )}
          </Select>
        </RadixSelect.Trigger>
      </RMTooltip>

      <RadixSelect.Content asChild position="item-aligned">
        <SelectContent data-mobile={mobile}>
          {children}

          <RadixSelect.Item asChild value="add-storyteller">
            <SelectItem>
              <RMIconButton
                tooltip={null}
                icon={faPlus}
                backgroundColor="transparent"
                color="white"
                stateColor="darken-primary"
              />

              <RMText type="sans" size="xs" bold color="on-primary">
                New project
              </RMText>
            </SelectItem>
          </RadixSelect.Item>
        </SelectContent>
      </RadixSelect.Content>
    </RadixSelect.Root>
  );
}

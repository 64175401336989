import { styled } from '@linaria/react';

export const Footer = styled.div`
  display: flex;
  gap: var(--spacing-md);
  padding: var(--spacing-md) var(--spacing-md) 0;

  [data-has-scroll='true'] &[data-hide-separator='false'] {
    border-top: 1px solid var(--border-weak);
  }

  body[data-mobile='false'] & {
    padding-top: var(--spacing-xl);
  }
`;

import { useLocation } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';
import { RMTooltip } from '@/components/RMTooltip/RMTooltip';
import { useIsMobileViewport, useIsTabletViewport } from '@/hooks/useIsMobileViewport';

import { NavbarItemRoot } from './NavbarItem.styles';

type NavbarItemProps = {
  id?: string;
  text: string;
  path: string;
  pathAliases?: string[];
  activeIcon: IconDefinition;
  inactiveIcon: IconDefinition;
  disabled?: boolean;
  target?: '_blank';
  onClick?: () => void;
};

export function NavbarItem({
  id,
  text,
  path,
  pathAliases,
  activeIcon,
  inactiveIcon,
  disabled = false,
  target,
  onClick,
}: NavbarItemProps) {
  const isMobile = useIsMobileViewport();
  const isTablet = useIsTabletViewport();
  const location = useLocation();
  const isActive = (location.pathname === path || pathAliases?.some((alias) => alias === location.pathname)) ?? false;

  return (
    <RMTooltip message={isTablet ? text : null}>
      <NavbarItemRoot
        id={id}
        to={disabled ? '' : path}
        target={target}
        data-active={isActive}
        data-disabled={disabled}
        onClick={onClick}
      >
        {(isTablet || !isMobile) && <FontAwesomeIcon icon={isActive ? activeIcon : inactiveIcon} size="xl" />}

        {!isTablet && (
          <RMText type={isMobile ? 'serif' : 'sans'} size={isMobile ? 'm' : 'xs'} color="primary" bold>
            {text}
          </RMText>
        )}
      </NavbarItemRoot>
    </RMTooltip>
  );
}

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';

export interface PromptNotFoundPageProps {
  onGoToPromptsPage: () => void;
}

export function PromptNotFoundPage({ onGoToPromptsPage }: PromptNotFoundPageProps) {
  return (
    <RecordingLayout.Root dark Header={<RecordingLayout.Header step="hidden" hideSeparator />}>
      <RMDialog.Root open>
        <RMDialog.Content>
          <RMDialog.Header title="Deleted prompt" />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              Someone deleted this prompt
            </RMText>
          </RMDialog.Body>
          <RMDialog.Footer>
            <RMButton background="primary" fullWidth onClick={onGoToPromptsPage}>
              View my prompts
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </RecordingLayout.Root>
  );
}

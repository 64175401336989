import Cookies from 'js-cookie';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { useAsyncEffect } from '@/hooks';
import { getQueryParam } from '@/hooks/useQueryParam';
import { RementoPage } from '@/modules/routing';
import { MIGRATION_TOKEN_COOKIE_NAME, USER_COOKIE_DOMAIN, useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';

function redirect() {
  const basename: string = import.meta.env.VITE_PATH_BASENAME ?? '/';
  window.location.assign(getQueryParam('redirect') ?? basename);
}

function InternalTokenRedirectPage() {
  const { authService } = useServices();
  const user = useUser();

  useAsyncEffect(
    async (checkpoint) => {
      if (user === undefined) {
        return;
      }

      let migrationToken = 'not-authenticated';
      if (user !== null) {
        migrationToken = await authService.createCustomSignInToken();
        checkpoint();
      }

      Cookies.set(MIGRATION_TOKEN_COOKIE_NAME, migrationToken, {
        domain: USER_COOKIE_DOMAIN,
        expires: Date.now() + 15 * 60 * 1000,
      });
      redirect();
    },
    [authService, user],
  );

  return <PageLoader />;
}

export function TokenRedirectPage() {
  return (
    <RementoPage type="empty">
      <InternalTokenRedirectPage />
    </RementoPage>
  );
}

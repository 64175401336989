import { useId } from 'react';

import { RMText } from '../RMText/RMText.js';

import { Progress, ProgressWrapper, Step, StepBar, Stepper } from './RMStepper.styles.js';

export interface RMStepperItem {
  label: string;
  disabled?: boolean;
}

interface RMStepperProps {
  activeStepIndex: number;
  steps: RMStepperItem[];
  layout?: 'stepper' | 'progress';
  hideIndex?: boolean;
  onStepChange?: (stepIndex: number) => void;
}

export function RMStepper({ activeStepIndex, steps, layout, hideIndex = false, onStepChange }: RMStepperProps) {
  const id = useId();

  if (layout === 'stepper') {
    return (
      <Stepper role="tablist">
        {steps.map((step, index) => (
          <Step
            key={step.label}
            data-disabled={step.disabled}
            onClick={() => !step.disabled && onStepChange?.(index)}
            title={step.label}
            aria-disabled={step.disabled}
            aria-labelledby={id + '-' + step.label}
            role="tab"
          >
            <RMText
              id={id + '-' + step.label}
              as="label"
              type="sans"
              size="xxs"
              bold
              trackedOutCaps
              truncate
              color={index === activeStepIndex ? 'on-surface-secondary' : undefined}
              style={{ color: index !== activeStepIndex ? '#696E6B' : undefined }}
            >
              {hideIndex == true ? '' : `${index + 1} `}
              {step.label}
            </RMText>

            <StepBar data-disabled={step.disabled} active={index === activeStepIndex} />
          </Step>
        ))}
      </Stepper>
    );
  }

  return (
    <ProgressWrapper>
      <RMText type="sans" size="xxs" color="on-surface-secondary" bold trackedOutCaps id={`${id}-label`}>
        {hideIndex == true ? '' : `${activeStepIndex + 1} `}
        {steps[activeStepIndex]?.label}
      </RMText>
      <Progress value={activeStepIndex + 1} max={steps.length} aria-labelledby={`${id}-label`} />
    </ProgressWrapper>
  );
}

import { RoutePath } from './types/routing.types.js';

export function getOnboardingIntroPath(): string {
  return RoutePath.OnboardingIntro;
}

export function getSetupPromptTypeSelectionPath(projectId: string): string {
  return RoutePath.SetupPromptTypeSelection.replace(':projectId', projectId);
}

export function getSetupTextPromptSelectionPath(projectId: string): string {
  return RoutePath.SetupTextPromptSelection.replace(':projectId', projectId);
}

export function getSetupPromptsReviewPath(projectId: string): string {
  return RoutePath.SetupPromptsReview.replace(':projectId', projectId);
}

export function getSetupPromptsFrequencyPath(projectId: string): string {
  return RoutePath.SetupPromptsFrequency.replace(':projectId', projectId);
}

export function getSetupPerspectivePath(projectId: string): string {
  return RoutePath.SetupPerspective.replace(':projectId', projectId);
}

export function getSetupCollaboratorsPath(projectId: string): string {
  return RoutePath.SetupCollaborators.replace(':projectId', projectId);
}

export function getCheckoutPath(): string {
  return RoutePath.Checkout;
}

export function getCheckoutAudiencePath(project: 'biography' | 'baby' = 'biography'): string {
  if (project === 'biography') {
    return RoutePath.CheckoutBiographyAudience;
  }
  return RoutePath.CheckoutBabyAudience;
}

export function getCheckoutBiographyDeliveryPath(): string {
  return RoutePath.CheckoutBiographyDelivery;
}

export function getCheckoutBiographyContactPath(): string {
  return RoutePath.CheckoutBiographyContact;
}

export function getCheckoutAutobiographyContactPath(): string {
  return RoutePath.CheckoutAutobiographyContact;
}

export function getCheckoutBiographyReviewPath(): string {
  return RoutePath.CheckoutBiographyReview;
}

export function getCheckoutAutobiographyReviewPath(): string {
  return RoutePath.CheckoutAutobiographyReview;
}

export function getCheckoutBabyReviewPath(): string {
  return RoutePath.CheckoutBabyReview;
}

export function getCheckoutBabyHostPath(): string {
  return RoutePath.CheckoutBabyHost;
}

export function getCheckoutBundlePath(): string {
  return RoutePath.CheckoutBundle;
}

export function getCheckoutGiftPath(skipProjectType?: boolean): string {
  const path = RoutePath.CheckoutGift;
  if (skipProjectType) {
    return path + '?skip-project-type=true';
  }
  return path;
}

export function getCheckoutFreeDetails(recipientPersonId: string, subscriptionOwnerPersonId: string): string {
  let path: string = RoutePath.CheckoutFreeDetails;
  path += `?recipient-person-id=${recipientPersonId}`;
  path += `&subscription-owner-person-id=${subscriptionOwnerPersonId}`;
  return path;
}

export function getCheckoutGetStartedPath(customParams?: URLSearchParams): string {
  const path = RoutePath.CheckoutGetStarted;
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getNewProjectPath(): string {
  return RoutePath.NewProject;
}

export function getQuestionsPath(projectId: string): string {
  return RoutePath.Questions.replace(':projectId', projectId);
}

export function getStoriesPath(projectId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.Stories.replace(':projectId', projectId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getStandaloneStoryViewPath(storyId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.StoryStandalone.replace(':storyId', storyId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getStoryViewPath(projectId: string, storyId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.Story.replace(':projectId', projectId).replace(':storyId', storyId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getProjectPromptsPath(
  projectId: string,
  options?: { openPromptPicker?: boolean; openPollCreator?: boolean },
): string {
  const path = RoutePath.Questions.replace(':projectId', projectId);
  const params = new URLSearchParams();

  if (options?.openPromptPicker) {
    params.set('openPromptPicker', 'true');
  }
  if (options?.openPollCreator) {
    params.set('openPollCreator', 'true');
  }

  if (params.size > 0) {
    return path + '?' + params.toString();
  }
  return path;
}

export function getProjectSettingsPath(
  projectId: string,
  options?: { openPerspectiveSettings?: boolean; openPromptSettings?: boolean },
): string {
  const path = RoutePath.ProjectSettings.replace(':projectId', projectId);
  if (options?.openPerspectiveSettings == true) {
    return path + '?open-perspective-settings=true';
  }
  if (options?.openPromptSettings == true) {
    return path + '?open-prompt-settings=true';
  }
  return path;
}

export function getBookOrderCoverDesignPath(projectId: string, bookId: string): string {
  return RoutePath.BookOrderCoverDesign.replace(':projectId', projectId).replace(':bookId', bookId);
}

export function getBookOrderStoriesPath(projectId: string, bookId: string): string {
  return RoutePath.BookOrderStories.replace(':projectId', projectId).replace(':bookId', bookId);
}

export function getBookOrderPreviewPath(projectId: string, bookId: string): string {
  return RoutePath.BookOrderPreview.replace(':projectId', projectId).replace(':bookId', bookId);
}

export function getBookOrderQuantityPath(projectId: string, bookId: string): string {
  return RoutePath.BookOrderQuantity.replace(':projectId', projectId).replace(':bookId', bookId);
}

export function getBookOrderFinalizePath(projectId: string, bookId: string): string {
  return RoutePath.BookOrderFinalize.replace(':projectId', projectId).replace(':bookId', bookId);
}

export function getBookOrderCompletePath(projectId: string, bookOrderId: string): string {
  return RoutePath.BookOrderComplete.replace(':projectId', projectId).replace(':bookOrderId', bookOrderId);
}

export function getBookReorderPreviewPath(projectId: string, bookOrderId: string): string {
  return RoutePath.BookReorderPreview.replace(':projectId', projectId).replace(':bookOrderId', bookOrderId);
}

export function getBookReorderQuantityPath(projectId: string, bookOrderId: string): string {
  return RoutePath.BookReorderQuantity.replace(':projectId', projectId).replace(':bookOrderId', bookOrderId);
}

export function getBookReorderFinalizePath(projectId: string, bookOrderId: string): string {
  return RoutePath.BookReorderFinalize.replace(':projectId', projectId).replace(':bookOrderId', bookOrderId);
}

export function getSigninPath(options?: { backupLocalData?: boolean; defaultEmail?: string | null }): string {
  const params = new URLSearchParams();
  if (options?.backupLocalData) {
    params.set('backup-local-data', 'true');
  }

  if (options?.defaultEmail) {
    params.set('fallback-signin-email', options.defaultEmail);
  }

  if (params.size > 0) {
    return RoutePath.Signin + '?' + params.toString();
  }
  return RoutePath.Signin;
}

export function getInviteSigninPath(options?: {
  backupLocalData?: boolean;
  defaultEmail?: string | null;
  inviteToken?: string | null;
}): string {
  const params = new URLSearchParams();
  if (options?.backupLocalData) {
    params.set('backup-local-data', 'true');
  }

  if (options?.defaultEmail) {
    params.set('fallback-signin-email', options.defaultEmail);
  }

  if (options?.inviteToken) {
    params.set('inviteToken', options.inviteToken);
  }

  if (params.size > 0) {
    return RoutePath.InviteSignin + '?' + params.toString();
  }
  return RoutePath.InviteSignin;
}

export function getSignoutPath(): string {
  return RoutePath.Signout;
}

export function getRecordingIntroPath(projectId: string, promptId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.RecordingIntro.replace(':projectId', projectId).replace(':promptId', promptId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getRecordingTypeSelectionPath(
  projectId: string,
  promptId: string,
  pageStep: 'prompt-review' | 'type-selection',
  customParams?: URLSearchParams,
): string {
  const path = RoutePath.RecordingTypeSelection.replace(':projectId', projectId).replace(':promptId', promptId);
  const params = new URLSearchParams(customParams);
  params.set('step', pageStep);
  return path + '?' + params.toString();
}

export function getRecordingPath(options: {
  projectId: string;
  promptId: string;
  type: 'audio' | 'video';
  catchup?: boolean;
  customParams?: URLSearchParams;
}): string {
  const path = RoutePath.Recording.replace(':projectId', options.projectId).replace(':promptId', options.promptId);
  const params = new URLSearchParams(options.customParams);
  params.set('type', options.type);
  if (options.catchup == true) {
    params.set('catchup-recording', 'true');
    params.set('referrer', 'project-record');
  }
  return path + '?' + params.toString();
}

export function getAccountSettingsPath(openManageSubscription?: boolean, resumeSubscription?: boolean): string {
  const params = new URLSearchParams();
  if (openManageSubscription) {
    params.set('open-manage-subscription', 'true');
  }
  if (resumeSubscription) {
    params.set('resume-subscription', 'true');
  }
  return RoutePath.AccountSettings + '?' + params.toString();
}

export function getAccountReactivatePath(): string {
  return RoutePath.AccountReactivate;
}

export function getAccountRenewPromoPath(): string {
  return RoutePath.AccountRenewPromo;
}

export function getSubscriptionCancelledSurveyPath(): string {
  return RoutePath.SubscriptionCancelledSurvey;
}

export function getRecordingPromptRecordedPath(projectId: string, promptId: string): string {
  return RoutePath.RecordingPromptRecorded.replace(':projectId', projectId).replace(':promptId', promptId);
}

export function getRecordingPaywallPath(projectId: string, promptId: string): string {
  return RoutePath.RecordingPaywall.replace(':projectId', projectId).replace(':promptId', promptId);
}

export function getRecordingRecoveryPath(): string {
  return RoutePath.RecordingRecovery;
}

export function getProjectRecordPath(projectId: string, referrer?: string, customParams?: URLSearchParams): string {
  const path = RoutePath.ProjectRecord.replace(':projectId', projectId);
  let searchParams: URLSearchParams | null = null;
  if (customParams != null) {
    searchParams = customParams;
  }
  if (referrer != null) {
    searchParams = new URLSearchParams(customParams);
    searchParams.set('referrer', referrer);
  }
  return searchParams != null ? path + '?' + searchParams.toString() : path;
}

export function getProjectRecordCustomizePath(projectId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.ProjectRecordCustomize.replace(':projectId', projectId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

export function getHighlightReelPath(storyId: string): string {
  return RoutePath.StoryHighlightReel.replace(':storyId', storyId);
}

export function getQuestionnairePath(name: string, state: 'question' | 'answer'): string {
  return RoutePath.Questionnaire.replace(':name', name).replace(':state', state);
}

export function getRecordingFinishPath(projectId: string, customParams?: URLSearchParams): string {
  const path = RoutePath.RecordingFinish.replace(':projectId', projectId);
  if (customParams) {
    return path + '?' + customParams.toString();
  }
  return path;
}

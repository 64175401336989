import { useLayoutEffect, useState } from 'react';

const mobileMediaQuery = window.matchMedia('(max-width:  768px)');
const tabletMediaQuery = window.matchMedia('(min-width: 769px) and (max-width: 1280px)');
const mobileLandscapeMediaQuery = window.matchMedia('(max-height: 430px)');
const portraitMediaQuery = window.matchMedia('(orientation: portrait)');

function isMobileMatches() {
  if (mobileMediaQuery.matches) {
    return true;
  }
  if (portraitMediaQuery.matches) {
    return false;
  }
  // We are in landscape mode. Check the height now to see if it's
  // a mobile device.
  return mobileLandscapeMediaQuery.matches;
}

function isTabletMatches() {
  return tabletMediaQuery.matches;
}

export function isMobileViewport() {
  return isMobileMatches();
}

export function isTabletViewport() {
  return isTabletMatches();
}

export function observeViewport(callback: (isMobile: boolean, isTablet: boolean) => void): () => void {
  let lastFiredValue: string | null = null;
  const listener = () => {
    const isMobile = isMobileViewport();
    const isTablet = isTabletViewport();
    const current = `${isMobile}${isTablet}`;
    if (current !== lastFiredValue) {
      callback(isMobile, isTablet);
      lastFiredValue = current;
    }
  };

  mobileMediaQuery.addEventListener('change', listener);
  mobileLandscapeMediaQuery.addEventListener('change', listener);
  tabletMediaQuery.addEventListener('change', listener);

  return () => {
    mobileMediaQuery.removeEventListener('change', listener);
    mobileLandscapeMediaQuery.removeEventListener('change', listener);
    tabletMediaQuery.removeEventListener('change', listener);
  };
}

export function useIsMobileViewport() {
  const [isMobile, setIsMobile] = useState(() => isMobileViewport());

  useLayoutEffect(() => {
    return observeViewport((isMobile, _) => setIsMobile(isMobile));
  }, []);

  return isMobile;
}

export function useIsTabletViewport() {
  const [isTablet, setIsTablet] = useState(() => isTabletViewport());

  useLayoutEffect(() => {
    return observeViewport((_, isTablet) => setIsTablet(isTablet));
  }, []);

  return isTablet;
}

export function useIsMobileOrTabletViewport() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(() => isTabletViewport() || isMobileViewport());

  useLayoutEffect(() => {
    return observeViewport((isMobile, isTablet) => setIsMobileOrTablet(isMobile || isTablet));
  }, []);

  return isMobileOrTablet;
}

export function useIsDesktopViewport(): boolean {
  const isMobileOrTabletViewport = useIsMobileOrTabletViewport();
  return !isMobileOrTabletViewport;
}

import { PromptType } from '@remento/types/project';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';
import dayjs from 'dayjs';

import { Events } from '../analytics.types.js';

import { OnboardingAnalyticsService, OnboardingCompletedEventPayload } from './onboarding-analytics.types.js';

export class DefaultOnboardingAnalyticsService implements OnboardingAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onOnboardingArrived(): void {
    this.browserAnalytics.track(Events.OnboardingArrive);
  }

  onOnboardingStarted(): void {
    this.browserAnalytics.track(Events.OnboardingStart);
  }

  onOnboardingPromptTypeSelected(type: PromptType): void {
    this.browserAnalytics.track(Events.OnboardingSubmitPromptTypeSelect, { type });
  }

  onOnboardingPromptsReviewed(count: number): void {
    this.browserAnalytics.track(Events.OnboardingSubmitPromptReview, { count });
  }

  onOnboardingCompleted(payload: OnboardingCompletedEventPayload): void {
    this.browserAnalytics.track(Events.OnboardingComplete, {
      ...payload,
      sendGift: dayjs(payload.sendGift).format('MM/DD/YYYY'),
    });
  }

  onOnboardingEnded(): void {
    this.browserAnalytics.track(Events.OnboardingEnd);
  }
}

import React, { ComponentProps, useMemo } from 'react';

import { ColorsTokens } from '../../styles/theme/colorTokens';

import { Text } from './RMText.styles';

export type RMTextSize = 'xxs' | 'xs' | 's' | 's2' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl';
type RMTextTypeface = 'sans' | 'serif';
type RMTextLineHeight = 'xs' | 's' | 'm' | 'l' | 'xl';

type RMTextProps = Omit<ComponentProps<'span'>, 'color'> & {
  type: RMTextTypeface;
  size: RMTextSize;
  color?: keyof typeof ColorsTokens;
  bold?: boolean | 'bold' | 'medium';
  trackedOutCaps?: boolean;
  underline?: boolean;
  align?: 'left' | 'right' | 'center' | 'justify';
  truncate?: boolean;
  lineHeight?: RMTextLineHeight;
  italic?: boolean;
  cursor?: 'default' | 'pointer';
  as?: React.ElementType;
};

export function RMText({
  type,
  size,
  color,
  bold,
  trackedOutCaps,
  underline = false,
  align = 'left',
  truncate = false,
  lineHeight,
  italic,
  cursor = 'default',
  as,
  ...props
}: RMTextProps) {
  const fontFamily = useMemo(() => (type === 'sans' ? 'FKGroteskNeue' : 'Adonis'), [type]);

  const fontSize = useMemo(() => {
    const fontSizes: Record<RMTextSize, string> = {
      xxs: '0.75rem',
      xs: '0.875rem',
      s: '1rem',
      s2: '1.125rem',
      m: type === 'serif' ? '1.25rem' : '1.125rem',
      l: '1.5625rem',
      xl: '1.9375rem',
      xxl: '2.4375rem',
      xxxl: '3.0625rem',
    };

    return fontSizes[size];
  }, [size, type]);

  const lineHeightSize = useMemo(() => {
    const lineHeightSizes: Record<RMTextLineHeight, string> = {
      xs: '1.225rem',
      s: '1.4rem',
      m: '1.575rem',
      l: '1.875rem',
      xl: '2.7125rem',
    };

    if (!lineHeight) {
      return 'unset';
    }

    if (lineHeightSizes[lineHeight]) {
      return lineHeightSizes[lineHeight];
    }

    console.warn(`Tracked out caps not supported for the ${lineHeight} line height`);

    return 'unset';
  }, [lineHeight]);

  const fontWeight = useMemo(() => (bold === 'bold' || bold === true ? 700 : bold === 'medium' ? 500 : 400), [bold]);

  const letterSpacing = useMemo<string | undefined>(() => {
    if (!trackedOutCaps) {
      return undefined;
    }

    const letterSpacings: Partial<Record<RMTextSize, string>> = {
      xxs: '1.2px',
      xs: '1.4px',
    };

    if (letterSpacings[size]) {
      return letterSpacings[size];
    }

    console.warn(`Tracked out caps not supported for the ${size} text size`);
    return undefined;
  }, [size, trackedOutCaps]);

  return (
    <Text
      as={as}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      underline={underline}
      color={color ? `var(--${color})` : undefined}
      align={align}
      lineHeight={lineHeightSize}
      data-truncate={truncate}
      data-italic={italic}
      data-cursor={cursor}
      {...props}
    />
  );
}

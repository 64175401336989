import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { SurveyAnalyticsService } from './survey-analytics.types.js';

export class DefaultSurveyAnalyticsService implements SurveyAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onCheckoutSurveySubmitted(reason: string): void {
    this.browserAnalytics.track(Events.SurveyCheckout, {
      reason,
    });
  }

  onSubscriptionCancelledSurveySubmitted(reason: string): void {
    this.browserAnalytics.track(Events.SurveySubscriptionCancelled, {
      reason,
    });
  }
}

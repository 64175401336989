import { MarketingMetadata } from '@remento/types/marketing';
import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { CheckoutAnalyticsService, CheckoutArriveType } from './checkout-analytics.types.js';

export class DefaultCheckoutAnalyticsService implements CheckoutAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onCheckoutArrived(type: CheckoutArriveType): void {
    this.browserAnalytics.track(Events.CheckoutArrive, { type });
  }

  onCheckoutLeave(page: string): void {
    this.browserAnalytics.track(Events.CheckoutLeave, {
      page,
    });
  }

  onCheckoutPersonalized(variant: 'someone-else' | 'myself'): void {
    this.browserAnalytics.track(Events.CheckoutPersonalize, {
      variant,
    });
  }

  onCheckoutContactFormSubmitted(): void {
    this.browserAnalytics.track(Events.CheckoutContactForm);
  }

  onCheckoutStorytellerFormSubmitted(firstName: string, lastName: string): void {
    this.browserAnalytics.track(Events.CheckoutStorytellerForm, {
      'storyteller.name.first': firstName,
      'storyteller.name.last': lastName,
    });
  }

  onCheckoutAddonsChanged(extraBooks: number, extraEbook: boolean, legacyboxPhotos: number): void {
    this.browserAnalytics.track(Events.CheckoutAddonsSelect, {
      extraBooks,
      extraEbook,
      legacyboxPhotos,
    });
  }

  onCheckoutStarted(payload: MarketingMetadata): void {
    this.browserAnalytics.track(Events.CheckoutStart, payload);
  }

  onCheckoutGetStarted(): void {
    this.browserAnalytics.track(Events.CheckoutGetStarted);
  }

  onCheckoutSuccess(invoiceNumber: string, price: number, paid: number): void {
    this.browserAnalytics.track(Events.CheckoutSuccess, { invoiceNumber, price, paid });
  }

  onCheckoutProcessingCompleted(duration: number) {
    this.browserAnalytics.track(Events.CheckoutProcessingComplete, { duration });
  }
}

import { BrowserAnalytics } from '@remento/web-analytics-client/analytics.browser';

import { Events } from '../analytics.types.js';

import { PollAnalyticsService } from './poll-analytics.types.js';

export class DefaultPollAnalyticsService implements PollAnalyticsService {
  constructor(private browserAnalytics: BrowserAnalytics) {}

  onPollCreated(): void {
    this.browserAnalytics.track(Events.PollCreated, {});
  }

  onPollDeleted(): void {
    this.browserAnalytics.track(Events.PollDeleted, {});
  }

  onPollQuestionsShuffled(): void {
    this.browserAnalytics.track(Events.PollQuestionsShuffled, {});
  }

  onPollVoted(): void {
    this.browserAnalytics.track(Events.PollVoted, {});
  }
}

import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsUser, AnonymousAnalyticsUser } from '@remento/web-analytics-client/@types';
import { EventProperties } from '@remento/web-analytics-client/analytics.browser';

import { logger } from '@/logger.ts';
import { DatadogTracingService, ErrorListener } from '@/services/analytics/datadog/datadog-tracing.types';
import { LocalStoreRepository } from '@/services/local/local-store';

import { version } from '../../../../package.json';

export class DefaultDatadogTracingService implements DatadogTracingService {
  private errorListeners = new Set<ErrorListener>();

  constructor(
    sessionStorage: LocalStoreRepository,
    config: {
      apiHost: string;
      apiPath: string;
      applicationId: string;
      clientToken: string;
      site: string;
      env: string;
      service: string;
      throttledErrors: string[];
      ignoredErrors: string[];
    },
  ) {
    const proxy: LogsInitConfiguration['proxy'] = (options) => {
      return `https://${config.apiHost}${config.apiPath}${options.path}?${options.parameters}`;
    };

    datadogLogs.init({
      clientToken: config.clientToken,
      site: config.site,
      env: config.env,
      version,
      service: config.service,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      proxy,
    });

    datadogRum.init({
      applicationId: config.applicationId,
      clientToken: config.clientToken,
      site: config.site,
      env: config.env,
      version,
      service: config.service,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [import.meta.env.VITE_API_URL],
      beforeSend: (event) => {
        if (event.type === 'error') {
          const ignoredError = config.ignoredErrors.find((error) => event.error.message.includes(error));
          if (ignoredError) {
            logger.warn('ERROR.IGNORED', () => ({
              message: event.error.message,
              stack: event.error.stack,
              ignoredError,
            }));
            return false;
          }

          const throttledError = config.throttledErrors.find((error) => event.error.message.includes(error));
          const shouldThrottle = throttledError != null && sessionStorage.getItem(throttledError) != null;
          if (shouldThrottle) {
            logger.warn('ERROR.THROTTLED', () => ({
              message: event.error.message,
              stack: event.error.stack,
              throttledError,
            }));
            return false;
          }

          event.error.fingerprint = event.error.message;

          if (event.error.id == null) {
            return false;
          }

          const now = Date.now();
          const errorUrl = `https://${import.meta.env.VITE_ANALYTICS_DD_SITE}/error-tracking?query=%40error.id%3A${
            event.error.id
          }&fromUser=false&refresh_mode=paused&source=all&from_ts=${new Date(now - 3600000).getTime()}&to_ts=${new Date(
            now + 3600000,
          ).getTime()}&live=false`;

          this.errorListeners.forEach((cb) => cb(errorUrl));

          if (throttledError != null) {
            sessionStorage.setItem(throttledError, true);
          }
        }

        return true;
      },
    });
  }

  identify(user: AnalyticsUser): void {
    const ddUser = {
      segment: user.type === 'anonymous' ? 'anonymous' : 'credential',
      id: user.id,
      ...user.traits,
    };
    datadogRum.setUser(ddUser);
    datadogLogs.setUser(ddUser);
  }

  reset(user: AnonymousAnalyticsUser): void {
    datadogLogs.clearUser();
    datadogRum.clearUser();
    this.identify(user);
  }

  async track(event: string, props?: EventProperties): Promise<void> {
    datadogRum.addAction(event, props);
  }

  async page(page: string): Promise<void> {
    datadogRum.startView(page);
  }

  // Datadog traces errors automatically. This method exists to create events
  // that are linked to the error logs and send them to the analytics services.
  // We could take the analytics service in the constructor but that would imply
  // running code before the tracing starts and that is risky, since something
  // could happen, and we would have no trace of it.
  onError(callback: ErrorListener) {
    this.errorListeners.add(callback);
    return () => this.errorListeners.delete(callback);
  }
}

import { useLayoutEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';

import { isFullScreen, observeIsFullscreen } from '@/hooks/useIsFullscreen';
import { isMobileViewport, isTabletViewport, observeViewport } from '@/hooks/useIsMobileViewport';
import { Colors } from '@/styles/base/colors';

import { DialogManagerContainer } from '../dialog/containers/DialogManager.container.js';
import { FullscreenStoreProvider, useCreateFullscreenStore, useFullscreenStore } from '../media-player/states/index.js';

import { ROUTE_THEME, TERMLY_ALLOWLIST } from './routes.config.js';
import { getRouteFromPathname } from './routes.js';

function InternalRootRoute() {
  const fullscreenStore = useFullscreenStore();
  const { pathname } = useLocation();
  const route = useMemo(() => getRouteFromPathname(pathname), [pathname]);
  const routeTheme = route ? ROUTE_THEME[route] : null;
  const termlyEnabled = route ? TERMLY_ALLOWLIST.includes(route) : false;

  const [termlyLoaded, setTermlyLoaded] = useState(false);

  useLayoutEffect(() => {
    document.body.setAttribute('data-mobile', String(isMobileViewport()));
    document.body.setAttribute('data-tablet', String(isTabletViewport()));
    return observeViewport((isMobile: boolean, isTablet: boolean) => {
      document.body.setAttribute('data-mobile', String(isMobile));
      document.body.setAttribute('data-tablet', String(isTablet));
    });
  }, []);

  useLayoutEffect(() => {
    document.body.setAttribute('data-fullscreen', String(isFullScreen(fullscreenStore)));
    return observeIsFullscreen(fullscreenStore, (isFullscreen) => {
      document.body.setAttribute('data-fullscreen', String(isFullscreen));
    });
  }, [fullscreenStore]);

  // This is kinda sketchy but onLoad is not working with Helmet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onTermlyLoaded = setTermlyLoaded;

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={routeTheme ?? Colors.spruce[15]} />
        {termlyEnabled && (
          <script
            type="text/javascript"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onload="window.onTermlyLoaded(true)"
            src="https://app.termly.io/resource-blocker/f51d9643-80ce-41a1-936e-96527e0cfcc8?autoBlock=off&masterConsentsOrigin=https://www.remento.co"
          />
        )}
      </Helmet>
      {termlyEnabled && termlyLoaded && (
        <iframe src="https://www.remento.co/private/termly-sync" style={{ display: 'none' }} />
      )}
      <Outlet />
      <DialogManagerContainer />
    </>
  );
}

export function RootRoute() {
  const createFullscreenStore = useCreateFullscreenStore();
  const fullscreenStore = useMemo(() => createFullscreenStore(), [createFullscreenStore]);

  return (
    <FullscreenStoreProvider value={fullscreenStore}>
      <InternalRootRoute />
    </FullscreenStoreProvider>
  );
}

import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMWordmark } from '@/components/RMWordmark/RMWordmark.tsx';

export const Background = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background-color: var(--surface);
  transition: background-color 0.4s ease-in-out;

  &[data-dark='true'] {
    background-color: var(--surface-always-dark);
  }
`;

export const Root = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 568px;
  display: flex;
  flex-flow: column;
  padding-bottom: var(--spacing-4xl);

  body[data-mobile='true'] & {
    max-width: 468px;
    padding-bottom: var(--spacing-md);
  }

  @media only screen and (max-height: 768px) {
    padding: var(--spacing-xs) 0;
  }
`;

export const Scrollable = styled.div`
  height: 100%;
  padding: var(--spacing-sm) var(--spacing-md);
  overflow-y: auto;

  body[data-mobile='false'] & {
    padding: var(--spacing-md);
  }
`;

export const ScrollableContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  &[data-centered='true'] {
    display: grid;
    align-items: center;
  }
`;

export const WordmarkWrapper = styled(motion.div)`
  position: absolute;
  padding-top: var(--spacing-2xl);
  padding-left: var(--spacing-md);
  background-color: var(--surface);
  transition: background-color 0.4s ease-in-out;

  &[data-dark='true'] {
    background-color: var(--surface-always-dark);
  }

  body[data-mobile='false'] & {
    left: 3rem;
  }

  body[data-mobile='true'] & {
    position: relative;
    padding-top: var(--spacing-md);
  }
`;

export const Wordmark = styled(RMWordmark)`
  width: 7rem;
`;

import { LocalStoreRepository } from '@/services/local/local-store';

import { RecordingSessionRepository } from './recording-session.types';

export class DefaultRecordingSessionRepository implements RecordingSessionRepository {
  constructor(private sessionStore: LocalStoreRepository) {}

  setTotalProjectRecordingTime(time: number): void {
    this.sessionStore.setItem('time', `${time}`);
  }

  getTotalProjectRecordingTime(): number | null {
    const time = this.sessionStore.getItem('time');
    return time != null ? parseFloat(time) : null;
  }
}
